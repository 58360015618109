import { useEffect, useMemo } from 'react';
import { useI18n } from 'next-localization';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { addCartItem, deleteCartItem } from 'components/cart/cart-slice';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';
import { pushViewItem, pushAddToCart, pushRemoveFromCart } from 'lib/google-analytics/commerce';
import CoveoProductImage from './CoveoProductImage';
import { CoveoRecommendedProductDetails } from './coveo-recommendations';

type CoveoRecommendationsCardProps = {
  product: CoveoRecommendedProductDetails;
  fetchPriceFromSalesforce: boolean;
  scrollPosition: Record<string, number> | null;
};

const defaultProductUrl = '/products';

function CoveoRecommendationsCard(props: CoveoRecommendationsCardProps): JSX.Element {
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const price = props.fetchPriceFromSalesforce
    ? props.product.priceSF
    : {
        unitPrice: props.product?.price.price,
        listPrice: props.product?.price.listPrice,
      };
  const listPrice = price?.listPrice;
  const unitPrice = price?.unitPrice;
  const isDiscounted = props.product !== null && unitPrice && listPrice && unitPrice < listPrice;
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { cartItemsData } = useAppSelector((state) => state.cart);

  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const cart = useAppSelector((state) => state.cart);
  const cartItem = cart?.cartItemsData?.cartSummary;

  const productInCart = useMemo(() => {
    return cartItemsData?.cartItems?.find((c) => c.cartItem.productId === props.product?.productId);
  }, [cartItemsData?.cartItems, props.product?.productId]);
  const customerInfo = useAppSelector((state) => state?.customer?.customerData);
  useEffect(() => {
    pushViewItem(
      currencyIsoCode ?? '',
      unitPrice ? unitPrice?.toString() : 'NA',
      {
        id: props.product?.productId ?? '',
        sku: props.product?.productId ?? '',
        name: props.product?.productName ?? '',
        price: unitPrice ? unitPrice?.toString() : 'NA',
        discount: 'NA',
        brand: 'NA',
        variant: props.product?.productSku ?? '',
        itemCategory: props.product?.primaryCategory,
        itemCategory2: props.product?.additionalCategory1,
        itemCategory3: props.product?.additionalCategory2,
        itemCategory4: props.product?.additionalCategory3,
        productSku: props.product?.productSku,
      },
      (customerInfo?.contactId as string) || ''
    );
  }, [
    props.product?.additionalCategory1,
    props.product?.additionalCategory2,
    props.product?.additionalCategory3,
    currencyIsoCode,
    unitPrice,
    props.product?.primaryCategory,
    props.product?.productId,
    props.product?.productName,
    props.product?.productSku,
    customerInfo?.contactId,
  ]);

  const onClickAddToCartHandler = (): void => {
    const details = {
      id: props.product?.productId ?? '',
      sku: props.product?.productId ?? '',
      name: props.product?.productName ?? '',
      price: unitPrice ? unitPrice?.toString() : 'NA',
      discount: 'NA',
      brand: 'NA',
      variant: props.product?.productSku ?? '',
      itemCategory: props.product?.primaryCategory,
      itemCategory2: props.product?.additionalCategory1,
      itemCategory3: props.product?.additionalCategory2,
      itemCategory4: props.product?.additionalCategory3,
      productSku: props.product?.productSku,
    };
    if (productInCart) {
      dispatch(
        deleteCartItem({ cartItemId: productInCart.cartItem.cartItemId, bearerToken: bearerToken })
      );
      pushRemoveFromCart(
        currencyIsoCode ?? '',
        cartItem?.totalListPrice?.toString() ?? '',
        details,
        (customerInfo?.contactId as string) || ''
      );
    } else {
      dispatch(
        addCartItem({ sku: props.product?.productId ?? '', quantity: 1, bearerToken: bearerToken })
      );

      pushAddToCart(currencyIsoCode ?? '', unitPrice ? unitPrice?.toString() : 'NA', details);
    }
  };

  return (
    <div
      className={`border rounded w-full border-base-normal flex flex-col items-center px-3 text-almost-black bg-white relative`}
    >
      <a
        href={props.product?.productUrl ?? defaultProductUrl}
        className="text-center font-bold text-lg inline-block md:h-3/5 mb-3"
      >
        <div className="min-h-126 overflow-hidden h-4/6 my-5 mx-3">
          <CoveoProductImage product={props.product} scrollPosition={props.scrollPosition} />
        </div>
        <h3 className="font-bold text-lg min-h-20p text-center my-4 w-full leading-5.4 font-avidwalsheimbold hover:underline">
          {props.product?.productName}
        </h3>
      </a>
      <div className="text-xl text-center my-4 min-h-44">
        <div
          className={`${isDiscounted ? 'visible' : 'invisible'} text-neutral text-xs line-through`}
        >
          <span>
            {t(`Currency_Symbol_${currencyIsoCode}`)}
            {listPrice}
          </span>
        </div>
        <div className="">
          {unitPrice !== undefined && unitPrice > 0 && (
            <span>
              {t(`Currency_Symbol_${currencyIsoCode}`)}
              {unitPrice}
            </span>
          )}
          {unitPrice !== undefined && unitPrice == 0 && (
            <>
              <span className="product-price accent-2 tracking-narrow">
                {t('PricingCard_Free')}
              </span>
            </>
          )}
        </div>
      </div>
      <button
        onClick={(): void => {
          onClickAddToCartHandler();
        }}
        className="text-center font-bold text-base btn-primary py-10px px-3 my-4"
        data-id={props.product?.productId}
      >
        {productInCart ? t('Cart_In_Cart') : t('Cart_Add')}
      </button>
    </div>
  );
}

export default CoveoRecommendationsCard;
