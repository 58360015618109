import { Field, LinkField, RichText, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  createCheckoutSession,
  removeCheckoutSession,
  setSelectedSource,
  updateCheckout,
} from 'components/checkout/checkout-slice';
import { ComponentProps } from 'lib/component-props';
import { useI18n } from 'next-localization';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useWebStoreCurrencies } from 'lib/commerce/webstore/webstore-hooks';
import { changeCurrency } from 'components/cart/cart-slice';
import PlaceOrderLink from '../../components/checkout/shop/PlaceOrderLink';
import DropdownMenu from 'components/dropdown/shop/DropdownMenu';
import { useSession } from 'next-auth/react';
import { setCheckoutStage } from 'components/checkout/checkout-slice';
import { useCartData } from 'components/cart/cart-hooks';
import Spinner from 'components/spinner/Spinner';
import { getStoredCountry } from 'lib/commerce/countries/countries-provider';
import { AnalyticsProductItem, pushBeginCheckout } from 'lib/google-analytics/commerce';
import dynamic from 'next/dynamic';
import Checkbox from 'components/checkbox/shop/Checkbox';
import { getBearerToken } from 'lib/authentication/account-provider';
import { CartRuleSitecore } from '../cart/CartSummary';
import { getCartItemsLimits } from 'components/checkout/checkout-helpers';
const ExpressPayments = dynamic(() => import('components/cart/shop/ExpressPayments'));
const CouponDetails = dynamic(() => import('components/cart/shop/CouponDetails'));
const CartItem = dynamic(() => import('components/cart/shop/CartItem'));

type SummaryProps = ComponentProps & {
  fields: {
    data?: {
      datasource?: {
        title: Field<string>;
        eulaUS: Field<string>;
        eulaNonUS: Field<string>;
        link: {
          jsonValue: LinkField;
        };
        orderProcessingLink: {
          jsonValue: LinkField;
        };
        cartRules: {
          targetItems: CartRuleSitecore[];
        };
      } & CheckoutSummaryLabels;
    };
  };
};

export type CheckoutSummaryLabels = {
  checkoutGreetingUser: Field<string> | undefined;
  checkoutGreetingBase: Field<string> | undefined;
  checkoutProceedUS: Field<string> | undefined;
  checkoutProceedNonUS: Field<string> | undefined;
  checkoutTaxes: Field<string> | undefined;
  checkoutOrderTotal: Field<string> | undefined;
  cartTotal: Field<string> | undefined;
  checkoutSubTotal: Field<string> | undefined;
};

const CheckoutSummary = (props: SummaryProps): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const {
    checkoutData,
    checkoutId,
    checkoutDataStatus,
    selectedSource,
    checkoutStage,
    billingAddress,
  } = useAppSelector((state) => state.checkout);

  const currencies = useWebStoreCurrencies();
  const currencyOptions = currencies.map((currency) => {
    return { value: currency, label: `${currency} ${t(`Currency_Symbol_${currency}`)}` };
  });
  const countries = useAppSelector((state) => state.countries.countriesData);

  const customerInfo = useAppSelector((state) => state.customer);
  const customerBillingCountry = billingAddress?.country;
  const customerDataStatus = customerInfo?.customerDataStatus;

  const cartItemData = useCartData();
  const cart = useAppSelector((state) => state.cart);
  const currencyIsoCode = cart?.currencyIsoCode;

  const cartItems = cartItemData?.cartItems;
  const prevCartItems = useRef(cartItems);
  const cartSummary = cartItemData?.cartSummary;
  const cartId = cartItemData?.cartSummary.cartId as string;
  const cartItemStatus = cart.cartItemsDataStatus;

  const checkout = useAppSelector((state) => state.checkout);
  const checkoutStatus = checkout.checkoutDataStatus;
  const createOrderStatus = checkout.createOrderStatus;
  const applyCouponStatus = cart?.applyCouponStatus;
  const country = getStoredCountry();

  const showFinalTotals = checkoutDataStatus === 'succeeded' && selectedSource != null;
  const total = showFinalTotals
    ? checkoutData?.digitalRiverResponse?.totalAmount ?? 0
    : cartSummary?.totalProductAmountAfterAdjustments ?? 0;
  const subTotal = showFinalTotals ? checkoutData?.digitalRiverResponse?.subtotal ?? 0 : 0;
  const taxes = showFinalTotals ? checkoutData?.digitalRiverResponse?.totalTax ?? 0 : 0;

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [sessionRecreationTried, setSessionRecreationTried] = useState(false);
  const [lastTotal, setLastTotal] = useState(0);

  const cartItemsLimits = getCartItemsLimits(
    props.fields?.data?.datasource?.cartRules?.targetItems ?? []
  );
  const labels: CheckoutSummaryLabels = {
    checkoutGreetingUser: props?.fields?.data?.datasource?.checkoutGreetingUser,
    checkoutGreetingBase: props?.fields?.data?.datasource?.checkoutGreetingBase,
    checkoutProceedUS: props?.fields?.data?.datasource?.checkoutProceedUS,
    checkoutProceedNonUS: props?.fields?.data?.datasource?.checkoutProceedNonUS,
    checkoutTaxes: props?.fields?.data?.datasource?.checkoutTaxes,
    checkoutOrderTotal: props?.fields?.data?.datasource?.checkoutOrderTotal,
    cartTotal: props?.fields?.data?.datasource?.cartTotal,
    checkoutSubTotal: props.fields.data?.datasource?.checkoutSubTotal,
  };

  useEffect(() => {
    // once user selected a source we updating checkout currency
    // to source address country currency

    const updateCurrency = async (): Promise<void> => {
      const countryCurrency = countries.find(
        (countryData) => customerBillingCountry === countryData.countryCode
      )?.currencyISoCode;
      if (countryCurrency && countryCurrency !== currencyIsoCode) {
        await dispatch(
          changeCurrency({ currencyIsoCode: countryCurrency, bearerToken: bearerToken })
        );
        await dispatch(removeCheckoutSession());
      }
    };

    if (customerBillingCountry && countries?.length > 0) {
      updateCurrency();
    }
  }, [
    customerBillingCountry,
    customerDataStatus,
    dispatch,
    currencyIsoCode,
    bearerToken,
    countries,
  ]);

  useEffect(() => {
    // we have to create checkout session only after selectiong a source
    // it's getting called as well immediately after removing existing checkout session
    if (bearerToken == '') {
      return;
    }
    if (selectedSource == null || (cartId && cartId == null)) {
      return;
    }
    if (checkoutId == null) {
      dispatch(createCheckoutSession({ store: 'Webstore', bearerToken: bearerToken }));
    }
  }, [bearerToken, cartId, checkoutId, dispatch, selectedSource]);

  useEffect(() => {
    // if checkout session has already been created and user changes cart items
    if (cartItems !== prevCartItems.current) {
      checkoutId !== null && dispatch(removeCheckoutSession());
      prevCartItems.current = cartItems;
    }
  }, [cartItems, checkoutId, dispatch]);

  useEffect(() => {
    // we updating checkout session and attach source to it
    // when a source is selected or changed by the user
    if (bearerToken == '') {
      return;
    }

    setAcceptedTerms(false);
    if (selectedSource == null || checkoutId == null || (cartId && cartId == null)) {
      return;
    }

    const handleSource = async (): Promise<void> => {
      const response = await dispatch(
        updateCheckout({
          checkoutId: checkoutId,
          sourceId: selectedSource?.source.id ?? '',
          bearerToken: bearerToken,
          cartId: cartId,
        })
      );
      if (
        !response?.payload &&
        response?.type === 'checkout/updateCheckout/rejected' &&
        !sessionRecreationTried
      ) {
        // sometimes DR rejects a selected payment method to be attached to the current session
        // because of some previous errors, so here we are trying to overcome it by creating a new session and setting the method again
        await dispatch(removeCheckoutSession());
        await dispatch(
          setSelectedSource({
            source: selectedSource.source,
            isNew: false,
          })
        );
        setSessionRecreationTried(true);
      }
    };
    handleSource();
  }, [dispatch, selectedSource, checkoutId, bearerToken, cartId, t, sessionRecreationTried]);

  useEffect(() => {
    // once user selected a source we go to 'confirmation' stage
    if (checkoutDataStatus === 'succeeded' && selectedSource !== null) {
      dispatch(setCheckoutStage('confirmation'));
    }
  }, [checkoutDataStatus, dispatch, selectedSource]);

  useEffect(() => {
    // google analitycs event
    if (currencyIsoCode == null || cartItemData == null) {
      return;
    }

    const cartTotal = parseFloat(cartItemData.cartSummary.totalProductAmountAfterAdjustments);
    if (lastTotal == cartTotal) {
      return;
    }

    const couponCode = cartItemData?.cartSummary?.coupons?.[0]?.couponCode;

    pushBeginCheckout(
      currencyIsoCode,
      cartTotal.toString(),
      couponCode ?? '',
      cartItemData.cartItems.map((item, index): AnalyticsProductItem => {
        const cartItem = item.cartItem;
        return {
          id: cartItem.productId,
          sku: cartItem.productDetails.sku,
          name: cartItem.name,
          price: cartItem.salesPrice,
          discount: (
            parseFloat(cartItem.listPrice) -
            parseFloat(cartItem.salesPrice) +
            Math.abs(parseFloat(cartItem.totalAdjustmentAmount))
          )
            .toFixed(2)
            .toString(),
          variant: cartItem.productDetails.fields.XC_SubscriptionType__c,
          brand: cartItem.productDetails.fields.VendorName__c,
          index: index.toString(),
          quantity: cartItem.quantity,
          listName: 'Checkout',
          listId: 'Checkout_0',
          itemCategory: cartItem.productCategoryPaths.primary?.at(-1)?.name,
          itemCategory2: cartItem.productCategoryPaths.path1?.at(-1)?.name,
          itemCategory3: cartItem.productCategoryPaths.path2?.at(-1)?.name,
          itemCategory4: cartItem.productCategoryPaths.path3?.at(-1)?.name,
          productSku: cartItem.productDetails.sku,
        };
      }),
      (customerInfo?.customerData?.contactId as string) || ''
    );
    setLastTotal(cartTotal);
  }, [cartItemData, currencyIsoCode, customerInfo?.customerData?.contactId, lastTotal]);

  const onChangeCurrencyHandler = async (currency: string): Promise<void> => {
    if (currency == currencyIsoCode) {
      return;
    }

    dispatch(changeCurrency({ currencyIsoCode: currency, bearerToken: bearerToken }));
  };

  const onChangeCheckboxHandler = (isChecked: boolean): void => {
    setAcceptedTerms(isChecked);
  };

  return (
    <>
      <div className="summary relative w-full max-w-450 xl:max-w-none mx-auto border border-base-normal p-6 rounded bg-white overflow-hidden">
        <h3 className="text-almost-black font-bold mb-4 text-2xl 2xl:text-28 text-center xl:text-left">
          {customerInfo?.customerData?.firstName != null && (
            <span>{`${labels?.checkoutGreetingUser?.value} ${customerInfo.customerData.firstName}, `}</span>
          )}
          {labels.checkoutGreetingBase?.value}
        </h3>
        {currencyIsoCode && (
          <DropdownMenu
            key={currencyIsoCode}
            defaultValue={{
              value: currencyIsoCode,
              label: `${currencyIsoCode} ${t(`Currency_Symbol_${currencyIsoCode}`)}`,
            }}
            options={currencyOptions}
            passValueToParentFunction={onChangeCurrencyHandler}
            className="mb-6"
            isEditable={false}
          />
        )}

        {(checkoutStatus == 'loading' ||
          cartItemStatus == 'loading' ||
          createOrderStatus == 'loading' ||
          applyCouponStatus === 'loading') && <Spinner />}
        {cartItems != null && cartSummary != null && (
          <>
            {cartItems.map((item) => {
              return (
                <CartItem
                  key={item.cartItem.cartItemId}
                  cartItem={item.cartItem}
                  currencyIsoCode={cartSummary.currencyIsoCode}
                  editable={checkoutStage !== 'confirmation'}
                  showPricingAndTerms={showFinalTotals}
                  quantityLimits={cartItemsLimits.find(
                    (el) => el.productId === item.cartItem.productId
                  )}
                />
              );
            })}
            <div className="wrapper flex flex-col text-almost-black">
              <CouponDetails isEditable={!showFinalTotals} />
              {showFinalTotals && (
                <>
                  {cartSummary.coupons.length <= 0 && (
                    <h3 className="font-normal text-base flex justify-between space-x-10 mb-2">
                      <span>{labels?.checkoutSubTotal?.value}</span>
                      <span>
                        {t(`Currency_Symbol_${currencyIsoCode}`)}
                        {subTotal}
                      </span>
                    </h3>
                  )}
                  <h3 className="font-normal text-base flex justify-between space-x-10 mb-2">
                    <span>{labels.checkoutTaxes?.value}</span>
                    <span>
                      {t(`Currency_Symbol_${currencyIsoCode}`)}
                      {taxes}
                    </span>
                  </h3>
                </>
              )}

              <h3 className="font-normal text-2xl flex justify-between space-x-10 mb-4">
                <span>{`${
                  showFinalTotals ? labels.checkoutOrderTotal?.value : labels.cartTotal?.value
                }`}</span>
                <span className="font-bold">
                  {t(`Currency_Symbol_${currencyIsoCode}`)}
                  {total}
                </span>
              </h3>
              {showFinalTotals &&
                props.fields?.data?.datasource &&
                (country === 'US' ? (
                  <RichText
                    tag="div"
                    field={props.fields?.data?.datasource?.eulaUS}
                    className="accept-terms body rte mt-4 text-base mb-6 [&>*>a]:text-primary hover:[&>*>a]:underline"
                  />
                ) : (
                  <Checkbox
                    checkboxKey={acceptedTerms.toString()}
                    sitecoreLabel={props.fields?.data?.datasource?.eulaNonUS}
                    passValueToParentFunction={onChangeCheckboxHandler}
                    defaultChecked={acceptedTerms}
                    className="accept-terms mt-4 mb-6"
                  />
                ))}

              {country === 'US'
                ? props.fields?.data?.datasource?.link && (
                    <PlaceOrderLink
                      label={labels.checkoutProceedUS?.value as string}
                      link={props.fields?.data?.datasource?.link.jsonValue}
                      orderProcessingLink={
                        props.fields?.data?.datasource?.orderProcessingLink.jsonValue
                      }
                      acceptedTerms={true}
                    />
                  )
                : props.fields?.data?.datasource && (
                    <PlaceOrderLink
                      label={labels.checkoutProceedNonUS?.value as string}
                      link={props.fields?.data?.datasource?.link.jsonValue}
                      orderProcessingLink={
                        props.fields?.data?.datasource?.orderProcessingLink.jsonValue
                      }
                      acceptedTerms={acceptedTerms}
                    />
                  )}
            </div>

            {(!showFinalTotals || checkoutStage === 'payment-billing') && <ExpressPayments />}

            <div className="wrapper flex flex-col items-end">
              {!showFinalTotals && (
                <div className="mt-6 text-center text-sm text-neutral-medium">
                  * Does not include any applicable taxes, which are calculated during checkout.
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default withDatasourceCheck()<SummaryProps>(CheckoutSummary);
