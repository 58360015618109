import React from 'react';
import { useI18n } from 'next-localization';
import { useState } from 'react';
import { CartItemDetails, ProductDetails } from 'components/cart/cart-models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from 'lib/store/hooks';
import { updateCartItem } from 'components/cart/cart-slice';
import InlineSpinner from 'components/spinner/InlineSpinner';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';
import { isNonOkResponse } from 'lib/common/common-http-client';

export type CartItemProps = {
  name: string;
  totalAmount: number;
  totalListPrice: number;
  quantity: number;
  cartItemId: string;
  currencySymbol: string;
  productDetails: ProductDetails;
  requestRemove?: (cartItemId: string) => void;
  actionsAllowed: boolean;
};

const PricingAndTerms = (props: CartItemProps): JSX.Element => {
  const { t } = useI18n();

  const subscriptionType = props.productDetails.fields.XC_SubscriptionType__c;
  if (subscriptionType === 'N/A' || subscriptionType == '') {
    return <></>;
  }

  let termsAndPricing = '';
  switch (subscriptionType) {
    case '2YR':
      termsAndPricing = t('checkout_summary_line_item_2yr');
      break;
    case '3YR':
      termsAndPricing = t('checkout_summary_line_item_3yr');
      break;
    case 'APU':
      termsAndPricing = t('checkout_summary_line_item_apu');
      break;
    case 'APM':
      termsAndPricing = t('checkout_summary_line_item_apm');
      break;
    case 'MPM':
      termsAndPricing = t('checkout_summary_line_item_mpm');
      break;
  }

  const totalAmount = `${props.currencySymbol}${props.totalAmount}`;
  termsAndPricing = termsAndPricing.replace('{sku_price}', totalAmount);

  const annualCost = `${props.currencySymbol}${
    Math.round(props.totalAmount * 12 * 100 + Number.EPSILON) / 100
  }`;
  termsAndPricing = termsAndPricing.replace('{yearly_price}', annualCost);

  return (
    <div className="text-[.6rem] leading-normal sm:text-xs italic mt-2">{termsAndPricing}</div>
  );
};

const MarketplaceCartItem = (props: CartItemProps): JSX.Element => {
  const [quantityValue, setQuantityValue] = useState<number>(props.quantity);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useI18n();
  const { data: session } = useSession();

  const updateItem = (increm: number): void => {
    const newValue = quantityValue + increm;
    if (newValue <= 0) {
      return;
    }
    setShowSpinner(true);
    const bearerToken = getBearerToken(session);
    dispatch(
      updateCartItem({ cartItemId: props.cartItemId, quantity: newValue, bearerToken: bearerToken })
    )
      .unwrap()
      .then((resp) => {
        if (resp == null || isNonOkResponse(resp)) {
          setShowSpinner(false);
          return;
        }
        const cartItemUpdate = resp.data as CartItemDetails;
        setQuantityValue(+cartItemUpdate.quantity);
        setShowSpinner(false);
      })
      .catch((error) => {
        console.log(`Can't update Cart for cartItemId ${props.cartItemId}: ${error.message}`);
        setShowSpinner(false);
      });
  };

  return (
    <div
      className={`py-2.5 text-mp-txt-neutralLight ${
        props.actionsAllowed ? 'border-b border-mp-txt-neutralLight' : ''
      }`}
    >
      <div className="inline-block w-1/2 sm:w-2/3 lg:w-3/4 whitespace-normal mb-3 pr-3 md:mb-0">
        <div className="">
          <div className="overflow-ellipsis overflow-hidden whitespace-nowrap">
            {props.name}
            {props.productDetails.variationAttributes?.XC_SubscriptionTerm__c?.value && (
              <span>, {props.productDetails.variationAttributes.XC_SubscriptionTerm__c.value}</span>
            )}
          </div>
          <PricingAndTerms {...props} />
        </div>
      </div>
      <div className="inline-block w-1/2 sm:w-1/3 lg:w-1/4 align-top">
        {props.actionsAllowed && (
          <div className="inline-block w-2/3 align-top">
            <div className="flex flex-row justify-start">
              {showSpinner && (
                <div className="w-[88px]">
                  <InlineSpinner width={3} height={15} color="#20BBD0" />
                </div>
              )}
              {!showSpinner && (
                <>
                  <button
                    className="w-6 h-6 border border-mp-txt-neutralLight bg-mp-txt-neutralLight hover:bg-mp-background-primary hover:border-mp-background-primary rounded text-mp-txt-almostBlack"
                    onClick={(): void => updateItem(-1)}
                  >
                    -
                  </button>
                  <input
                    className="w-8 h-6 border border-mp-txt-neutralLight bg-mp-txt-almostBlack rounded text-mp-txt-neutralLight text-center mx-1"
                    type="number"
                    value={quantityValue}
                    readOnly
                  />
                  <button
                    className="w-6 h-6 border border-mp-txt-neutralLight bg-mp-txt-neutralLight hover:bg-mp-background-primary hover:border-mp-background-primary rounded text-mp-txt-almostBlack"
                    onClick={(): void => updateItem(1)}
                  >
                    +
                  </button>
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="text-mp-txt-neutralLight hover:text-mp-txt-primary w-6 h-6 ml-1 cursor-pointer"
                    onClick={(): void => {
                      if (!props.requestRemove) {
                        return;
                      }
                      props.requestRemove(props.cartItemId);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        )}
        {!props.actionsAllowed && (
          <div className="inline-block w-2/3 text-start align-top pl-7">
            <span className="md:hidden">{t('Marketplace_Cart_Quantity')}: </span>
            {quantityValue}
          </div>
        )}
        <div className="inline-block text-base text-end font-medium text-mp-txt-primary w-1/3">
          <div className="w-full text-end">
            {props.currencySymbol}
            {props.totalAmount}
          </div>
          {props.totalAmount !== props.totalListPrice && (
            <div className="text-sm line-through text-end text-mp-txt-neutralLight">
              {props.currencySymbol}
              {props.totalListPrice}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default MarketplaceCartItem;
