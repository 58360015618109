import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { ColumnProps } from './jsxtablemodels';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { GetClassFromTheme } from '../../lib/utils/button-theme';

const JSXColumn = (column: ColumnProps): JSX.Element => {
  const buttonThemeClass = GetClassFromTheme(
    column.theme?.jsonValue?.fields?.value?.value ?? 'small primary'
  );

  const columnCountClass = `${column.columnCount ? `w-1/` + column.columnCount : ''}`;

  return (
    <td className={`p-3 min-w-200 ${columnCountClass}`}>
      {column.icon && (
        <span>
          <FontAwesomeIcon icon={column?.icon?.targetItem?.value?.jsonValue?.value as IconProp} />
        </span>
      )}

      {column.detail && (
        <RichText
          tag="div"
          className={`rte [&_a]:underline [&_a]:text-primary`}
          field={column?.detail?.jsonValue}
        />
      )}

      {column.link?.jsonValue?.value?.href && (
        <div className="pt-6">
          <NextLink field={column.link?.jsonValue} className={`${buttonThemeClass} w-fit`} />
        </div>
      )}
    </td>
  );
};
export default JSXColumn;
