import {
  Field,
  ImageField,
  LinkField,
  RichText,
  isEditorActive,
} from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { BaseCarouselProps } from 'components/carousel/BaseCarouselProps';

type ProductListCarouselContentCardProps = BaseCarouselProps & {
  fields: {
    image: ImageField;
    link: LinkField;
    badge: Field<string>;
    noFollow?: Field<boolean>;
  };
};

const ProductListCarouselContentCard = (
  props: ProductListCarouselContentCardProps
): JSX.Element => {
  const editorActive = isEditorActive();
  const linkText = props.fields?.link?.value?.text ?? '';
  const hasBadge = props.fields?.badge?.value != null && props.fields.badge.value.length > 0;
  const largeVariant =
    props.params?.MaxItemsPerSlideOnDesktop != null &&
    props.params?.MaxItemsPerSlideOnDesktop == '4';

  return (
    <div className={`carousel-item flex p-4 ${editorActive ? '' : 'flex-1'}`}>
      <div className="flex flex-col flex-1 rounded bg-white border border-1 border-base-normal overflow-hidden ">
        <NextLink
          className="flex-1 relative"
          field={props.fields?.link}
          linkCustomizations={props.fields}
          hideText={true}
        >
          <div className="aspect-4/3 relative overflow-hidden">
            <NextImage field={props.fields?.image} layout="fill" />
          </div>
          {(hasBadge || editorActive) && (
            <div className="absolute right-3 top-3 bg-info-light rounded-lg uppercase text-info px-2 py-2px text-14 font-bold">
              <RichText className="rte leading-none" field={props.fields?.badge} />
            </div>
          )}

          <div
            className={`intro-text text-primary text-center ${
              largeVariant ? 'text-2xl p-3' : 'text-base p-2'
            }`}
          >
            {linkText}
          </div>
        </NextLink>
      </div>
    </div>
  );
};

export default ProductListCarouselContentCard;
