import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroupProps } from 'react-multi-carousel';

function VideoCarouselButtonGroup(props: ButtonGroupProps): JSX.Element {
  const currentSlide = props.carouselState?.currentSlide ?? 0;
  const slidesToShow = props.carouselState?.slidesToShow ?? 0;
  const totalItems = props.carouselState?.totalItems ?? 0;
  const needsNavigation = totalItems > slidesToShow;
  const atEnd = currentSlide === totalItems - slidesToShow;
  return (
    <div
      className={`w-full absolute ${needsNavigation ? 'top-39p md:top-42p lg:top-43p' : 'top-1/2'}`}
    >
      {currentSlide != 0 && (
        <button
          className=" w-8 h-8  md:w-11 md:h-11 border-2 border-base-medium-dark text-almost-black rounded-full absolute -left-17px sm:-left-50px md:-left-60px xl:-left-60px bg-white flex justify-center items-center hover:bg-primary-lighter hover:border-primary-lighter focus:bg-primary-lighter focus:border-primary active:bg-primary-light"
          onClick={(): void => {
            if (props.previous != null) {
              props?.previous();
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
      )}
      {needsNavigation && !atEnd && (
        <button
          className="z-10 w-8 h-8 md:w-11 md:h-11 border-2 border-base-medium-dark text-almost-black  rounded-full absolute -right-17px sm:-right-50px md:-right-60px xl:-right-60px bg-white flex justify-center items-center hover:bg-primary-lighter hover:border-primary-lighter focus:bg-primary-lighter focus:border-primary active:bg-primary-light"
          onClick={(): void => {
            if (props.next != null) {
              props?.next();
            }
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      )}
    </div>
  );
}

export default VideoCarouselButtonGroup;
