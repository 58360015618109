import { useSession } from 'next-auth/react';
import { useContext, useEffect, useState } from 'react';
import { AtomicCustomTab, defineCustomElements } from '@ksuamel/custom-atomic-react';
import dynamic from 'next/dynamic';
import CoveoSearchEngineContext from 'lib/coveo/coveo-context';
import { SearchEngine } from '@coveo/headless';
import { useRouter } from 'next/router';
import { ComponentProps } from 'lib/component-props';
import { toKeyValuePair } from 'lib/utils/string-format';

const BaseSearchResults = dynamic(() => import('../../components/search/BaseSearchResults'));

export type CustomSearchProps = ComponentProps & {
  fields: {
    labels: { value: string };
  };
};

const SiteSearch = (props: CustomSearchProps): JSX.Element => {
  const { data: session } = useSession();
  const labels = props.fields.labels?.value ? toKeyValuePair(props?.fields?.labels?.value) : {};
  const t = (key: string): string => {
    return labels[key] || key;
  };
  console.log('T', props);
  const allContentLabel = t('ALL_CONTENT');
  const router = useRouter();
  const isAllContent = (str): boolean => {
    const tabHash = str
      .split('#')[1]
      ?.split('&')
      .find((hash) => hash.split('=')[0] === 'tab');
    if (!tabHash) {
      return true;
    }
    return tabHash?.split('=')[1].replace('%20', ' ') == allContentLabel;
  };
  const [allCategoriesSelected, setAllCategoriesSelected] = useState(isAllContent(router.asPath));

  useEffect(() => {
    defineCustomElements();
  }, []);

  const searchEngine = useContext(CoveoSearchEngineContext) as unknown as SearchEngine;

  const fullFacetList =
    'communitySource,productCategory,onSale,price,vendor,pluginOs,pluginProduct,audioformat,kbProductCategory,kbProduct,kbDocumentType,kbLanguage,videoChannel,videoCategory';

  const itemListName = 'Global Search Results';
  const itemListId = `GlobalSearchResults_0`;

  return (
    <>
      {searchEngine && (
        <BaseSearchResults
          listName={itemListName}
          listId={itemListId}
          searchEngine={searchEngine}
          hideFacets={allCategoriesSelected}
          labels={props.fields?.labels}
          leftRailTopContent={
            <div className="tab-container bg-background p-4">
              <div className="category-title btn-text-transparent flex font-bold justify-between w-full text-lg ripple-parent ripple-relative">
                <div className="truncate ripple-relative">Category</div>
              </div>

              <ul className="category-list mt-3">
                <li
                  className="category-list-item relative flex items-center"
                  onClick={(): void => setAllCategoriesSelected(true)}
                >
                  <AtomicCustomTab
                    label={allContentLabel}
                    expression=""
                    excludedFacets="communitySource,productCategory,onSale,price,vendor,pluginOs,pluginProduct,audioformat,kbProductCategory,kbProduct,kbDocumentType,kbLanguage,videoChannel,videoCategory"
                    isActive={true}
                    fullFacetList={fullFacetList}
                  ></AtomicCustomTab>
                </li>

                <li
                  className="category-list-item relative flex items-center"
                  onClick={(): void => setAllCategoriesSelected(false)}
                >
                  <AtomicCustomTab
                    label={t('PRODUCTS')}
                    expression="@objecttype==(ParentProduct)"
                    excludedFacets="communitySource,kbProductCategory,kbProduct,kbDocumentType,videoChannel,videoCategory,kbLanguage"
                    isActive={false}
                    fullFacetList={fullFacetList}
                  ></AtomicCustomTab>
                </li>

                <li
                  className="category-list-item relative flex items-center"
                  onClick={(): void => setAllCategoriesSelected(false)}
                >
                  <AtomicCustomTab
                    label={t('KNOWLEDGE_BASE')}
                    expression="@documenttype==(Knowledge)"
                    excludedFacets="communitySource,productCategory,onSale,price,vendor,pluginOs,pluginProduct,audioformat,videoChannel,videoCategory"
                    isActive={false}
                    fullFacetList={fullFacetList}
                  ></AtomicCustomTab>
                </li>

                <li
                  className="category-list-item relative flex items-center"
                  onClick={(): void => setAllCategoriesSelected(false)}
                >
                  <AtomicCustomTab
                    label={t('VIDEOS')}
                    expression="@documenttype==(Video)"
                    excludedFacets="communitySource,productCategory,onSale,price,vendor,pluginOs,pluginProduct,audioformat,kbProductCategory,kbProduct,kbDocumentType,kbLanguage"
                    isActive={false}
                    fullFacetList={fullFacetList}
                  ></AtomicCustomTab>
                </li>

                {session && (
                  <li
                    className="category-list-item relative flex items-center"
                    onClick={(): void => setAllCategoriesSelected(false)}
                  >
                    <AtomicCustomTab
                      label={t('SEARCH_COMMUNITIES')}
                      expression="@documenttype==(Community)"
                      excludedFacets="productCategory,onSale,price,vendor,pluginOs,pluginProduct,audioformat,kbProductCategory,kbProduct,kbDocumentType,kbLanguage,videoChannel,videoCategory"
                      isActive={false}
                      fullFacetList={fullFacetList}
                    ></AtomicCustomTab>
                  </li>
                )}
              </ul>
            </div>
          }
        />
      )}
    </>
  );
};

export default SiteSearch;
