import SubscriptionPricingCard from './SubscriptionPricingCard';
import {
  CurrencyVariationData,
  PricingCardSubscriptionProps,
  ProductSetVariationData,
} from './pricing-card-subscription-props';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useEffect, useMemo, useState } from 'react';
import { ProductVariationsData, Variant } from 'lib/commerce/product/model/product-models';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import DropdownMenu from 'components/dropdown/shop/DropdownMenu';
import { useWebStoreCurrencies } from 'lib/commerce/webstore/webstore-hooks';
import { useI18n } from 'next-localization';
import { changeCurrency } from 'components/cart/cart-slice';
import BaseItemQuantityNudger from 'components/cart/shop/BaseItemQuantityNudger';
import Carousel from 'components/carousel/Carousel';
import SubscriptionPricingCardCarouselButtons from './SubscriptionPricingCardCarouselButtons';
import InlineSpinner from 'components/spinner/InlineSpinner';
import SubscriptionPricingCardsContent from './SubscriptionPricingCardsContent';
import { themeColors } from 'src/components/foundation/non-sitecore/themes/themes-options';
import { pushViewItems } from 'lib/google-analytics/commerce';
import { useCartData } from 'components/cart/cart-hooks';
import { useSearchParams } from 'next/navigation';
import { ProductDetails } from '../../foundation/commerce/graphql/product-details-query-response';
import Spinner from 'components/spinner/Spinner';
import { ProductSet } from './pricing-card-subscription-response';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';

const getCachedVariations = (
  productSetVariationData: Array<ProductSetVariationData>,
  currencyIsoCode: string,
  productSetId?: string | null
): CurrencyVariationData | null => {
  currencyIsoCode = currencyIsoCode ?? 'USD';
  if (productSetVariationData == null || productSetId == null) {
    return null;
  }

  const productSetVariations = productSetVariationData.filter(
    (x) => x.productSetId == productSetId
  )[0];

  if (productSetVariations == null) {
    console.error('Failed to find variation data for productSet', productSetId);
    return null;
  }

  const variations = productSetVariations.variationData.filter(
    (x) => x.currency === currencyIsoCode
  )[0];
  if (variations == null) {
    console.error('Failed to find currency for product set', productSetId, currencyIsoCode);
    return null;
  }

  return variations;
};

const getVisibleData = (
  currencyVariationData: CurrencyVariationData
): { products: ProductDetails[]; variants: Variant[] } => {
  const sitecoreProductItem: ProductDetails[] = [];
  const visibleVariants: Variant[] = [];
  for (let i = 0; i < currencyVariationData.variants.length; i++) {
    const productId = currencyVariationData?.variants[i].productId;
    const result = currencyVariationData.productDetails.filter(
      (product) => product.productId === productId
    );

    if (result == null || result.length == 0) {
      continue;
    }

    sitecoreProductItem.push(result[0]);
    visibleVariants.push(currencyVariationData.variants[i]);
  }

  return {
    products: sitecoreProductItem,
    variants: visibleVariants,
  };
};

export const BaseSubscriptionPricingCards = (props: PricingCardSubscriptionProps): JSX.Element => {
  const { t } = useI18n();
  const currencies = useWebStoreCurrencies();
  const currencyOptions = currencies.map((currency) => {
    return { value: currency, label: `${currency} ${t(`Currency_Symbol_${currency}`)}` };
  });
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode) as string;
  const cart = useCartData();
  const dispatch = useAppDispatch();

  const searchParams = useSearchParams();
  const searchParamUserType = searchParams.get('usertype');
  let userTypeFromSearchParam;

  const userTypeItems =
    props.fields?.data?.datasource?.userTypeOptions?.targetItems != null
      ? props.fields.data.datasource.userTypeOptions.targetItems
      : null;

  const defaultUserTypeItem =
    props.fields?.data?.datasource?.defaultUserType?.targetItems != null
      ? props.fields?.data?.datasource?.defaultUserType?.targetItems
      : null;

  const defaultUserType = defaultUserTypeItem != null ? defaultUserTypeItem[0] : null;

  const [cartItemLimits, setCartItemLimits] = useState({
    min: defaultUserType?.minCartQuantity?.value
      ? Number(defaultUserType?.minCartQuantity?.value)
      : 1,
    max: defaultUserType?.maxCartQuantity?.value
      ? Number(defaultUserType?.maxCartQuantity?.value)
      : 99,
  });

  const [productSet, setProductSet] = useState(defaultUserType?.productSet.targetItems[0]);
  const [productVariations, setProductVariations] = useState<ProductVariationsData | null>(
    getCachedVariations(
      props.productSetVariationData,
      currencyIsoCode,
      productSet?.productSetId.value
    )
  );
  const [productItems, setProductItems] = useState<ProductDetails[] | null>(
    productVariations != null
      ? getVisibleData(productVariations as CurrencyVariationData).products
      : null
  );
  const [isLoading, setIsLoading] = useState(false);

  const [quantity, setQuantity] = useState<number>(cartItemLimits.min);
  const [showTotalLicenses, setShowTotalLicenses] = useState(
    defaultUserType?.showTotalLicenses?.jsonValue?.value
  );

  const pricingOptions = userTypeItems?.map((item) => {
    return { value: item.productSet.targetItems[0].productSetId.value, label: item.title.value };
  });
  const defaultPricingOption =
    defaultUserType != null
      ? {
          value: defaultUserType.productSet?.targetItems[0].productSetId.value ?? '',
          label: defaultUserType.title.value,
        }
      : null;

  if (searchParamUserType && pricingOptions) {
    switch (searchParamUserType) {
      case 'individual':
        userTypeFromSearchParam = pricingOptions.find((option) => option.label === 'Individuals');
        break;
      case 'edu':
        userTypeFromSearchParam = pricingOptions.find(
          (option) => option.label === 'Students & Teachers'
        );
        break;
      case 'teams':
        userTypeFromSearchParam = pricingOptions.find((option) => option.label === 'Teams');
        break;
      default:
        null;
    }
  }

  const colorClasses = props.colorClasses ?? themeColors.white;
  const cartPagePath = props.cartPagePath ?? '/cart';
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const customerInfo = useAppSelector((state) => state?.customer?.customerData);

  useEffect(() => {
    if (currencyIsoCode == null || productSet == null) {
      return;
    }

    setIsLoading(true);
    const variations = getCachedVariations(
      props.productSetVariationData,
      currencyIsoCode,
      productSet.productSetId.value
    );

    if (variations === null) {
      return;
    }

    setProductVariations(variations);

    const visibleData = getVisibleData(variations);
    const sitecoreProductItemIds = visibleData.products;
    const visibleVariants = visibleData.variants;

    setProductItems(sitecoreProductItemIds);
    if (sitecoreProductItemIds.length > 0) {
      setIsLoading(false);
    }

    if (currencyIsoCode == null || cart?.cartSummary == null) {
      return;
    }

    pushViewItems(
      currencyIsoCode,
      cart.cartSummary.totalProductAmountAfterAdjustments,
      visibleVariants.map((variant, index) => {
        return {
          id: variant.productId,
          sku: variant.productId,
          name: variant.name,
          price: variant.price.unitPrice.toString(),
          discount: (variant.price.listPrice - variant.price.unitPrice).toString(),
          brand: variant.brand,
          variant: variant?.subscriptionType ?? 'N/A',
          index: index.toString(),
          listName: 'Pick Plan',
          listId: `PickPlan_${props.rendering.uid}`,
          itemCategory: variant.productCategoryPaths.primary?.at(-1)?.name,
          itemCategory2: variant.productCategoryPaths.path1?.at(-1)?.name,
          itemCategory3: variant.productCategoryPaths.path2?.at(-1)?.name,
          itemCategory4: variant.productCategoryPaths.path3?.at(-1)?.name,
          productSku: variant?.sku,
        };
      }),
      (customerInfo?.contactId as string) || ''
    );
  }, [
    cart,
    currencyIsoCode,
    customerInfo?.contactId,
    productSet,
    props.productSetVariationData,
    props.rendering.uid,
  ]);

  const onChangePricingHandler = (pricingOption: string): void => {
    if (props.fields.data.datasource?.userTypeOptions == null) {
      return;
    }

    const newProductSet = props.fields.data.datasource.userTypeOptions.targetItems.find(
      (item) => item.productSet.targetItems[0].productSetId.value === pricingOption
    );
    const newCartItemsLimits = {
      min: newProductSet?.minCartQuantity.value ? Number(newProductSet?.minCartQuantity.value) : 1,
      max: newProductSet?.maxCartQuantity.value ? Number(newProductSet?.maxCartQuantity.value) : 99,
    };

    setProductSet(newProductSet?.productSet.targetItems[0] as ProductSet);
    setCartItemLimits(newCartItemsLimits);
    if (quantity < newCartItemsLimits.min) {
      setQuantity(newCartItemsLimits.min);
    } else if (quantity > newCartItemsLimits.max) {
      setQuantity(newCartItemsLimits.max);
    }
    setShowTotalLicenses(newProductSet?.showTotalLicenses?.jsonValue?.value);
  };

  const onChangeCurrencyHandler = (currency: string): void => {
    setIsLoading(true);
    if (currencyIsoCode === currency) {
      return;
    }

    dispatch(changeCurrency({ currencyIsoCode: currency, bearerToken: bearerToken }));
  };

  const onChangeQuantityHandler = (quantity: number): void => {
    setQuantity(quantity);
  };
  const productSetId = productSet?.productSetId?.value;
  return (
    <div className={`mx-auto ${colorClasses.general.textMainColorClass} mt-20`}>
      <Text
        tag="h1"
        field={props.fields?.data?.datasource?.productName}
        className="text-center tracking-widest-plus uppercase"
      />
      <Text
        tag="h3"
        field={props.fields?.data?.datasource?.subheading}
        className="intro-text text-center mb-10 md:mb-16 xl:mb-20"
      />
      <div
        id="subscription-table"
        className="mb-10 px-6 2xl:px-24 relative"
        data-set-id={productSetId}
      >
        <div
          className={`flex flex-col w-full items-center gap-y-10 mb-10 ${colorClasses.general.textMainColorClass}`}
        >
          <div className="flex w-full flex-col items-center md:items-baseline md:flex-row md:justify-center">
            {defaultPricingOption && pricingOptions && (
              <>
                <p className="body mb-2 md:mr-2">{t('SubscriptionPricingCard_ShowPricingFor')}</p>
                <DropdownMenu
                  key={userTypeFromSearchParam ? userTypeFromSearchParam : 'pricing'}
                  defaultValue={userTypeFromSearchParam || defaultPricingOption}
                  options={pricingOptions}
                  passValueToParentFunction={onChangePricingHandler}
                  className="w-full mb-4 md:w-fit md:mr-4"
                  colorClasses={colorClasses}
                />
              </>
            )}

            {currencyIsoCode && (
              <DropdownMenu
                key={currencyIsoCode}
                defaultValue={{
                  value: currencyIsoCode,
                  label: `${currencyIsoCode} ${t(`Currency_Symbol_${currencyIsoCode}`)}`,
                }}
                options={currencyOptions}
                passValueToParentFunction={onChangeCurrencyHandler}
                className="w-full md:w-fit"
                colorClasses={colorClasses}
              />
            )}
          </div>
          {showTotalLicenses && (
            <div className="flex flex-col items-center space-y-2">
              <p className="body-bold">{t('SubscriptionPricingCard_TotalLicenses')}</p>
              <BaseItemQuantityNudger
                quantity={quantity}
                passValueToParentFunction={onChangeQuantityHandler}
                colorClasses={colorClasses}
                minValue={cartItemLimits.min}
                maxValue={cartItemLimits.max}
                isReadOnly={false}
              />
            </div>
          )}
        </div>
        {isLoading && !productVariations?.variants && (
          <div className="relative">
            <InlineSpinner width={8} margin={6} />
          </div>
        )}

        {productVariations?.variants && (
          <>
            <div className="relative mx-auto xl:max-w-content" id="subscription-pricing-carousel">
              <Carousel
                MaxItemsPerSlideOnDesktop={productVariations.variants.length < 5 ? '4' : '3'}
                MaxItemsPerSlideOnTablet={productVariations.variants.length === 2 ? '2' : '1'}
                MaxItemsPerSlideOnMobile={'1'}
                desktopSize={1199}
                tabletSize={767}
                buttonGroup={
                  <SubscriptionPricingCardCarouselButtons
                    btnsColorClass={colorClasses.general.pageBgLighter}
                  />
                }
                customDotsGroup={props.caruselDots || undefined}
                carouselWrapperClass={`mx-auto max-w-350 xl:max-w-none 
                  ${productVariations.variants.length === 2 && 'md:max-w-800'} `}
                sliderClass={`flex justify-center flex-grow
                  ${
                    productVariations.variants.length < 5
                      ? '[&>li:first-child>div]:border-l-1 [&>li:first-child>div]:rounded-l [&>li:last-child>div]:rounded-r xl:[&>li:not(:first-child)>div]:border-l-0'
                      : 'xl:[&>li>div]:mr-8 xl:[&>li:last-child]:pr-4 xl:!p-4'
                  }
                  ${
                    productVariations.variants.length === 2 &&
                    'md:max-w-800 md:[&>li:first-child>div]:ml-auto md:[&>li:not(:first-child)>div]:border-l-0'
                  }`}
              >
                {productItems !== null &&
                  productVariations.variants.map((variation, index) => (
                    <SubscriptionPricingCard
                      key={variation.sku}
                      quantity={quantity}
                      productId={variation.productId}
                      sku={variation.sku}
                      productName={productItems[index]?.productName.value}
                      productNameFromSalesforce={variation.name}
                      brand={productItems[index]?.brand.value}
                      productCategoryPaths={variation.productCategoryPaths}
                      unitPrice={variation.price.unitPrice}
                      listPrice={variation.price.listPrice}
                      currencyCode={variation.price.currencyIsoCode}
                      subscriptionType={variation.subscriptionType}
                      featuredTheme={productItems[index]?.featuredTheme.targetItem.value.value}
                      featuredText={productItems[index]?.featuredText}
                      badgeText={productItems[index]?.badgeText}
                      featuresHeading={productItems[index]?.featuresHeading}
                      features={productItems[index]?.features.targetItems}
                      className={`subscription-pricing-card m-4 border border-base-medium-dark max-w-350 rounded xl:max-w-none !overflow-hidden shadow-card xl:mx-auto flex items-center 
                      ${
                        productVariations.variants.length < 5
                          ? 'xl:max-w-none xl:rounded-none xl:shadow-none '
                          : 'xl:border-base-light'
                      }
                      ${
                        productVariations.variants.length === 2 &&
                        'md:mx-0 md:max-w-400 md:rounded-none md:shadow-none'
                      }
                      ${colorClasses.general.textMainColorClass}`}
                      colorClasses={colorClasses}
                      cartPagePath={cartPagePath}
                    />
                  ))}
              </Carousel>
              {isLoading && <Spinner />}
            </div>
          </>
        )}

        {productSet && (
          <SubscriptionPricingCardsContent
            rendering={props.rendering}
            params={props.params}
            productSet={productSet}
            linkColorClass={colorClasses.subscriptionPricingCard.textFeaturedSecondary}
          />
        )}
      </div>
    </div>
  );
};

export default BaseSubscriptionPricingCards;
