import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useAppSelector } from 'lib/store/hooks';

type CheckoutStepperProps = ComponentProps & {
  fields: {
    paymentStepperStage1: Field<string>;
    paymentStepperStage1Label: Field<string>;
    paymentStepperStage2: Field<string>;
    paymentStepperStage2Label: Field<string>;
    paymentStepperStage3: Field<string>;
    paymentStepperStage3Label: Field<string>;
  };
};
const CheckoutStepper = (props: CheckoutStepperProps): JSX.Element => {
  const checkoutStage = useAppSelector((state) => state.checkout.checkoutStage);

  return (
    <ol className="flex items-center mb-57px max-w-308 mx-auto mt-16 2xl:mt-24">
      <li className="relative">
        {checkoutStage === 'sign-in' ? (
          <span className="peer w-28 h-28px bg-secondary rounded-full flex items-center justify-center text-base text-white">
            {props?.fields?.paymentStepperStage1?.value}
          </span>
        ) : (
          <span className="w-28 h-28px border-2 border-neutral bg-neutral-lightest rounded-full flex items-center justify-center text-base text-neutral">
            <FontAwesomeIcon icon={faCheck} />
          </span>
        )}
        <p className="text-base text-center text-base-dark absolute whitespace-nowrap left-1/2 -translate-x-1/2 peer-first:text-almost-black">
          {props?.fields?.paymentStepperStage1Label?.value}
        </p>
      </li>

      <span
        className={`border flex-grow ${
          checkoutStage === 'payment-billing' ||
          checkoutStage === 'payment-method' ||
          checkoutStage === 'confirmation'
            ? 'border-neutral'
            : 'border-neutral-lighter'
        }`}
      />
      <li className="relative">
        {checkoutStage === 'sign-in' && (
          <span className="w-28 h-28px bg-neutral-lightest rounded-full flex items-center justify-center text-base text-neutral">
            {props?.fields?.paymentStepperStage2?.value}
          </span>
        )}

        {(checkoutStage === 'payment-billing' || checkoutStage === 'payment-method') && (
          <span className="peer w-28 h-28px bg-secondary rounded-full flex items-center justify-center text-base text-white">
            {props?.fields?.paymentStepperStage2?.value}
          </span>
        )}

        {checkoutStage === 'confirmation' && (
          <span className="w-28 h-28px border-2 border-neutral bg-neutral-lightest rounded-full flex items-center justify-center text-base text-neutral">
            <FontAwesomeIcon icon={faCheck} />
          </span>
        )}
        <p className="text-base text-center text-base-dark absolute whitespace-nowrap left-1/2 -translate-x-1/2 peer-first:text-almost-black">
          {props?.fields?.paymentStepperStage2Label?.value}
        </p>
      </li>
      <span
        className={`border flex-grow ${
          checkoutStage === 'confirmation' ? 'border-neutral' : 'border-neutral-lighter'
        }`}
      />

      <li className="relative">
        {checkoutStage === 'confirmation' ? (
          <span className="peer w-28 h-28px bg-secondary rounded-full flex items-center justify-center text-base text-white">
            {props?.fields?.paymentStepperStage3?.value}
          </span>
        ) : (
          <span className="w-28 h-28px bg-neutral-lightest rounded-full flex items-center justify-center text-base text-neutral">
            {props?.fields?.paymentStepperStage3?.value}
          </span>
        )}
        <p
          className={`text-base text-center text-base-dark absolute whitespace-nowrap left-1/2 -translate-x-1/2 peer-first:text-almost-black`}
        >
          {props?.fields?.paymentStepperStage3Label?.value}
        </p>
      </li>
    </ol>
  );
};

export default withDatasourceCheck()<CheckoutStepperProps>(CheckoutStepper);
