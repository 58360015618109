import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Field,
  GetStaticComponentProps,
  ImageField,
  LinkField,
  RichText,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import VideoPlayerIcon from 'components/video/VideoPlayerIcon';
import BaseVideoModal from 'components/video/BaseVideoModal';
import { ComponentProps } from 'lib/component-props';
import { GraphQLClient } from 'lib/common/graphql-client';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
  ContentBlockResponse,
  VideoTargetItem,
} from 'components/content-block/content-block-response';
import getContentBlockQuery from 'components/content-block/content-block-query';
import { youtubeThumbnailProps } from '../VideoBlock';
import { useEffect, useState } from 'react';
import { getYoutubeThumbnail } from 'lib/youtube-thumbnail-fetcher';
import { useSSRErrorsHandler } from 'src/opentelemetry/handle-ssr-errors-hook';
import { SsrErrorPropsCreator } from 'src/opentelemetry/elastic-rum-ssrlog-creator';

type ContentBlockProps = ComponentProps & {
  fields: {
    image: ImageField;
    video: VideoTargetItem;
    icon: Field<string>;
    heading: Field<string>;
    description: Field<string>;
    title: Field<string>;
    link: LinkField;
    noFollow?: Field<boolean>;
  };
  params: {
    ImageShape: string;
  };
};
let backgroundClass;
const GetClassFromImageShape = (imageShape: string): string => {
  if (imageShape == null) {
    return '';
  }

  switch (imageShape.toLowerCase()) {
    case 'circle': {
      backgroundClass = 'before:rounded-full';
      return 'rounded-full';
    }

    case 'square': {
      backgroundClass = 'before:rounded-none';
      return 'rounded-none';
    }
    default: {
      backgroundClass = 'before:rounded-none';
      return 'rounded-none';
    }
  }
};

const ContentBlock = (props: ContentBlockProps): JSX.Element => {
  useSSRErrorsHandler(props.ssrErrors);
  const video = props.fields?.video;
  let videoThumbnail =
    video?.stillImage?.jsonValue?.value?.src != null && video?.stillImage?.jsonValue;

  const imageShapeClass = GetClassFromImageShape(props?.params?.ImageShape);

  const isYoutubeVideo = video?.sourceId != null;
  const isBrightcoveVideo = video?.videoReference != null;
  const thumbnailURL = video?.videoReference?.targetItems?.[0]?.ThumbnailURL?.value;

  let videoId: string | undefined;
  let playerId: string | undefined;

  if (isYoutubeVideo) {
    videoId = video?.sourceId?.value;
  } else if (isBrightcoveVideo) {
    videoId = video?.videoReference?.targetItems?.[0]?.ID?.value;
    playerId = video?.player?.targetItem?.[0]?.ID?.value;

    if (!videoThumbnail && thumbnailURL) {
      videoThumbnail = { value: { src: thumbnailURL } };
    }
  }

  const [youtubeVideoThumbnail, setyoutubeVideoThumbnail] = useState<youtubeThumbnailProps>();
  useEffect(() => {
    if (isYoutubeVideo) {
      if (!videoThumbnail) {
        videoId &&
          getYoutubeThumbnail(videoId).then((data: youtubeThumbnailProps) => {
            setyoutubeVideoThumbnail(data);
          });
      } else {
        const thumbnailURL = videoThumbnail?.value && videoThumbnail.value?.src;
        thumbnailURL && setyoutubeVideoThumbnail({ value: { src: thumbnailURL } });
      }
    }
  }, [isYoutubeVideo, videoId, videoThumbnail]);

  return (
    <li className="flex flex-col space-y-6 text-almost-black xl:flex-row xl:space-y-0 xl:space-x-8 ">
      {!props.fields.icon?.value && (
        <div
          className={`${imageShapeClass} relative aspect-square w-[175px] h-[175px] flex-shrink-0`}
        >
          {isYoutubeVideo ? (
            <BaseVideoModal
              brightcoveVideo={isBrightcoveVideo}
              youtubeVideo={isYoutubeVideo}
              videoId={videoId}
              playerId={playerId}
              openCtaClassName=""
              openCtaChildren={
                <div
                  className={`before:content-[' '] before:z-10 before:absolute ${backgroundClass}  before:bg-imageOverlayBlack before:top-0 before:left-0 before:w-[175px]  before:h-[175px]`}
                >
                  <span className={`${youtubeVideoThumbnail}`}></span>
                  {youtubeVideoThumbnail ? (
                    <NextImage
                      layout="fill"
                      field={youtubeVideoThumbnail}
                      width={175}
                      height={175}
                      className={`${imageShapeClass} w-[175px] h-[175px] bg-base-lighter relative flex justify-center`}
                    />
                  ) : (
                    <span className="w-[175px] h-[175px] bg-base-lighter relative flex justify-center" />
                  )}
                  <VideoPlayerIcon className="text-[40px] text-white border-[4px] rounded-full   border-playIconBorderBlack bg-playIconBorderBlack  border-opacity-70" />
                </div>
              }
            />
          ) : isBrightcoveVideo ? (
            <BaseVideoModal
              brightcoveVideo={isBrightcoveVideo}
              youtubeVideo={isYoutubeVideo}
              videoId={videoId}
              playerId={playerId}
              openCtaClassName=""
              openCtaChildren={
                <div
                  className={`before:content-[' '] before:z-10 before:absolute before:${imageShapeClass} before:bg-imageOverlayBlack before:top-0 before:left-0 before:w-full before:h-full`}
                >
                  {videoThumbnail ? (
                    <>
                      <NextImage
                        layout="fill"
                        field={videoThumbnail}
                        width={175}
                        height={175}
                        className={`${imageShapeClass} w-[175px] h-[175px] bg-base-lighter relative flex justify-center`}
                      />
                    </>
                  ) : (
                    <span className="w-[175px] h-[175px] bg-base-lighter relative flex justify-center" />
                  )}
                  <VideoPlayerIcon className="text-[40px] text-white border-[4px] rounded-full   border-playIconBorderBlack bg-playIconBorderBlack  border-opacity-70" />
                </div>
              }
            />
          ) : (
            props.fields.image && (
              <NextImage
                field={props.fields.image}
                width={175}
                height={175}
                className={`${imageShapeClass} w-[175px] h-[175px]`}
              />
            )
          )}
        </div>
      )}
      {props.fields.icon?.value && (
        <div className="w-[72px] h-[72px] rounded-full bg-base-light text-center flex flex-shrink-0 items-center justify-center">
          <FontAwesomeIcon
            icon={props.fields.icon?.value as IconProp}
            className="text-3xl text-secondary"
          />
        </div>
      )}
      <div className="flex flex-col">
        <Text tag="h3" field={props.fields.heading} className="lg:text-[1.75rem]" />
        <Text
          tag="p"
          field={props.fields.title}
          className="featured-user-title uppercase tracking-[4px] mt-2"
        />
        <RichText tag="p" field={props.fields.description} className="rte body mt-3" />
        <Link
          field={props.fields.link}
          linkCustomizations={props.fields}
          className="text-left btn-link p-0 hover:bg-transparent mt-3 focus:pr-4 w-fit"
          hideText
        >
          <span>{props.fields?.link?.value?.text}</span>
          <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
        </Link>
      </div>
    </li>
  );
};

/**
 * Will be called during SSG
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  if (
    rendering?.dataSource == null ||
    rendering?.dataSource == '' ||
    layoutData?.sitecore?.context?.language == null
  ) {
    return {};
  }

  const ssrErrorLogger = new SsrErrorPropsCreator();

  const language = layoutData.sitecore.context.language;
  const contentBlockData = await getContentBlockData(rendering.dataSource, language);
  if (!contentBlockData) {
    ssrErrorLogger.logError({
      functionName: 'getContentBlockData; ',
      errorMessage: `Failed to get response; Datasource: ${JSON.stringify(rendering.dataSource)};
      Langusge: ${language};
      Response: ${JSON.stringify(contentBlockData)}`,
    });
  }

  const propsObj = {
    fields: {
      image: contentBlockData?.datasource?.image?.jsonValue ?? null,
      icon: contentBlockData?.datasource?.contentBlockIcon?.targetItem?.value?.jsonValue ?? null,
      video: contentBlockData?.datasource?.videos?.targetItems[0] ?? null,
      title: contentBlockData?.datasource?.title?.jsonValue ?? null,
      heading: contentBlockData?.datasource?.heading?.jsonValue ?? null,
      description: contentBlockData?.datasource?.description?.jsonValue ?? null,
      link: contentBlockData?.datasource?.link?.jsonValue ?? null,
      noFollow: contentBlockData?.datasource?.noFollow?.jsonValue ?? null,
    },
    contentType: rendering?.params?.ImageShape ?? null,
  };

  return ssrErrorLogger.createProperty('ContentBlock', propsObj);
};

export const getContentBlockData = async (
  datasource: string,
  language: string
): Promise<ContentBlockResponse> => {
  const graphQLClient = GraphQLClient();
  return await graphQLClient.request<ContentBlockResponse>(getContentBlockQuery, {
    datasource: datasource,
    language: language,
  });
};

export default withDatasourceCheck()<ContentBlockProps>(ContentBlock);
