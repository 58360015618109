import React from 'react';

export type ProductPromoLabelProps = {
  promoValue: string;
};

const ProductPromoLabel = (props: ProductPromoLabelProps): JSX.Element => {
  return (
    <div className="absolute top-0 right-0 z-10">
      <label>
        <div>
          <div className="w-0 h-0 border-t-14 border-r-14 border-b-14 border-solid border-transparent border-r-mp-background-primary absolute top-0 right-130px"></div>
          <div className="bg-mp-background-primary text-center text-mp-txt-bright text-xxs leading-7 font-semibold uppercase tracking-1px w-130px h-28px">
            {props.promoValue}
          </div>
          <div className="w-0 h-0 border-t-10 border-r-10 border-b-10 md:border-t-8 md:border-r-8 md:border-b-8 border-solid border-transparent border-t-mp-background-primary absolute top28 right-0"></div>
        </div>
      </label>
    </div>
  );
};

export default ProductPromoLabel;
