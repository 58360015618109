import { faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'components/foundation/non-sitecore/Modal';
import { useState } from 'react';
import { TargetItemModal } from 'src/pages/api/product/models/product';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { Field, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ColorClassesType } from 'src/components/foundation/non-sitecore/themes/themes-options';

type SubscriptionPricingCardFeatureModalProps = TargetItemModal & {
  modalLinkText: Field<string>;
  colorClasses: ColorClassesType;
};

const SubscriptionPricingCardFeatureModal = (
  props: SubscriptionPricingCardFeatureModalProps
): JSX.Element => {
  const [displayModal, setDisplayModal] = useState(false);
  const { colorClasses } = props;

  const onClickOpenFeatureModalHandler = (): void => {
    if (!displayModal && typeof window != 'undefined' && window.document) {
      setDisplayModal(true);
      document.body.style.overflow = 'hidden';
    }
  };

  const onClickCloseFeatureModalHandler = (): void => {
    setDisplayModal(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <span>
      {displayModal && (
        <Modal
          onClose={onClickCloseFeatureModalHandler}
          show={displayModal}
          useModalBackdrop={false}
          titleClassName="text-almost-black"
          rootId="modal-root"
        >
          <div className="fixed flex items-center justify-center top-0 left-0 w-screen h-screen z-50">
            <div
              onClick={onClickCloseFeatureModalHandler}
              className="modal-backdrop absolute top-0 bg-avid-backdrop h-screen opacity-50 w-screen z-10"
            />
            <div
              className={`relative ${colorClasses.general.pageBgMain} ${colorClasses.general.textMainColorClass} flex flex-col items-center z-50 p-10 max-w-350 xl:max-w-850 rounded`}
            >
              <button
                type="button"
                className="absolute right-6 top-5 text-26"
                onClick={onClickCloseFeatureModalHandler}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
              <Text
                tag="h2"
                className="tracking-widest-plus uppercase mb-6"
                field={props.targetItem.heading}
              />
              <RichText className="rte body mb-10" field={props.targetItem.body} />
              <div onClick={onClickCloseFeatureModalHandler}>
                <NextLink
                  field={props.targetItem.link.jsonValue}
                  className={`${colorClasses.subscriptionPricingCard.btn} flex items-center`}
                >
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="text-sm ml-4 font-bold order-1"
                  />
                </NextLink>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <button onClick={onClickOpenFeatureModalHandler} className="underline small-text">
        <Text tag="span" field={props.modalLinkText} />
      </button>
    </span>
  );
};

export default SubscriptionPricingCardFeatureModal;
