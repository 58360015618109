import {
  Field,
  GetStaticComponentProps,
  ImageField,
  LinkField,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import Carousel from 'components/carousel/Carousel';
import Spinner from 'components/spinner/Spinner';
import ProductRecommendationsCard, {
  RecommendedProductDetails,
} from 'components/product-recommendations/ProductRecommendationsCard';
import { getWebstore } from 'lib/commerce/webstore/webstore-api';
import { isNonOkResponse } from 'lib/common/common-http-client';
import ProductRecommendationsCarouselButtons from 'components/product-recommendations/ProductRecommendationsCarouselButtons';
import { getMultiProductPricing } from 'lib/commerce/product/product-api';
import { Price } from 'lib/commerce/product/model/product-models';
import { SfResponse } from 'lib/common/model/common-models';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { useSSRErrorsHandler } from 'src/opentelemetry/handle-ssr-errors-hook';
import { SsrErrorPropsCreator } from 'src/opentelemetry/elastic-rum-ssrlog-creator';

export type ProductRecommendationsManualProps = ComponentProps & {
  fields: {
    data: {
      datasource: {
        title: Field<string>;
        products: {
          targetItems: [
            {
              productId: {
                value: string;
              };
              productCode: {
                value: string;
              };
              productName: {
                value: string;
              };
              externalProductImageThumbnailUrl: {
                value: string;
              };
              productImageThumbnail: {
                jsonValue: ImageField;
              };
              productDetailsPageLink: {
                jsonValue: LinkField;
              };
              term: {
                value: string;
              };
            },
          ];
        };
      };
    };
  };
  pricesData: Price[];
  scrollPosition: Record<string, number> | null;
};

const ProductRecommendationsManual: React.FC<ProductRecommendationsManualProps> = (props) => {
  useSSRErrorsHandler(props.ssrErrors);
  const products = props.fields.data.datasource.products.targetItems;
  const prices = props.pricesData;
  const productsArray: RecommendedProductDetails[] = products.map((item) => {
    return {
      productName: item.productName.value,
      productUrl: item.productDetailsPageLink?.jsonValue.value.href ?? '',
      productId: item.productId.value,
      productSku: item.productCode.value,
      primaryCategory: '',
      additionalCategory1: '',
      additionalCategory2: '',
      additionalCategory3: '',
      externalProductImageThumbnailUrl: item.externalProductImageThumbnailUrl?.value ?? '',
      productimageThumbnail: item.productImageThumbnail?.jsonValue ?? '',
      term: item?.term?.value ?? 'N/A',
    };
  });

  return (
    <>
      {productsArray !== null && (
        <div className="product-recommendations mb-5 relative">
          <Text
            tag="h2"
            field={props.fields.data.datasource.title}
            className="font-bold text-2xl leading-29.5 mb-2 pt-3 md:pt-0 text-center"
          />
          <div className="flex flex-row justify-center realative">
            {productsArray === null && <Spinner />}
            {productsArray && productsArray.length > 0 && (
              <div className="product-recommendations-carusel w-full">
                <div className="sm:mt-0 md:mt-4 relative">
                  <Carousel
                    MaxItemsPerSlideOnDesktop="3"
                    MaxItemsPerSlideOnTablet="2"
                    MaxItemsPerSlideOnMobile="1"
                    sliderClass={`flex flex-row justify-center flex-grow mb-1`}
                    itemClass="justify-center w-1/3 max-w-[380px]"
                    buttonGroup={<ProductRecommendationsCarouselButtons />}
                  >
                    {productsArray.map((product, index) => {
                      return (
                        <ProductRecommendationsCard
                          key={index}
                          product={product}
                          prices={prices.filter(
                            (priceObj) => priceObj.productId === product.productId
                          )}
                          scrollPosition={props.scrollPosition}
                        />
                      );
                    })}
                  </Carousel>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const getCommerceCurrencies = async (): Promise<Array<string>> => {
  const webStoreData = await getWebstore();
  if (isNonOkResponse(webStoreData)) {
    return ['USD'];
  }

  return webStoreData.data?.supportedCurrencies ?? ['USD'];
};

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  if (
    rendering?.dataSource == null ||
    rendering?.dataSource == '' ||
    layoutData?.sitecore?.context?.language == null
  ) {
    return {};
  }

  const ssrErrorLogger = new SsrErrorPropsCreator();

  const currencies = await getCommerceCurrencies();
  if (!currencies) {
    ssrErrorLogger.logError({
      functionName: 'getCommerceCurrencies; ',
      errorMessage: `Failed to get response;
      Response: ${JSON.stringify(currencies)}`,
    });
  }
  const productIds = rendering?.fields?.data['datasource'].products.targetItems.map(
    (prod) => prod.productId.value
  );

  const pricesData: Price[] = [];
  const responsesRequests: Promise<SfResponse<Price[]>>[] = [];
  currencies.forEach((currency) => {
    responsesRequests.push(getMultiProductPricing(productIds, currency));
  });
  const responses = await Promise.all(responsesRequests).catch((err) => {
    ssrErrorLogger.logError({
      functionName: 'getMultiProductPricing; ',
      errorMessage: `Failed to get response;
        productIds: ${productIds};
        Error: ${JSON.stringify(err)}`,
    });
    return Promise.resolve(responsesRequests);
  });
  responses.forEach((resp) => {
    if (!resp.success) {
      return;
    }
    resp.data.forEach((priceObj) => {
      pricesData.push(priceObj);
    });
  });

  const propsObj = {
    pricesData,
  };
  return ssrErrorLogger.createProperty('ProductRecommendationsManual', propsObj);
};

export default trackWindowScroll(
  withDatasourceCheck()<ProductRecommendationsManualProps>(ProductRecommendationsManual)
);
