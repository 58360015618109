import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { getCountries } from 'lib/commerce/countries/countries-slice';
import {
  getPaymentMethods,
  CustomSource,
  PaymentAddress,
  setSelectedSource,
} from 'components/checkout/checkout-slice';
import { useCartData } from 'components/cart/cart-hooks';
import {
  setCheckoutStage,
  updateCheckout,
  createCheckoutSession,
} from 'components/checkout/checkout-slice';
import { CartItem } from 'components/cart/cart-models';
import Spinner from 'components/spinner/Spinner';
import MarketplacePaymentMethods from '../../components/checkout/marketplace/MarketplacePaymentMethods';
import MarketplaceCheckoutSummary from '../../components/checkout/marketplace/MarketplaceCheckoutSummary';
import { useRouter } from 'next/router';
import { removeSavedExpressPayment } from 'components/checkout/checkout-provider';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';
import AlertBox from 'components/alert/AlertBox';
import useDigitalRiverInstance from 'components/checkout/dr-elements-hook';
import { useI18n } from 'next-localization';

export type MarketplaceCheckoutProps = {
  fields: {
    title: Field<string>;
    eula: Field<string>;
    thirdPartyTerms: Field<string>;
    continueShoppingLink: LinkField;
    link: LinkField;
    orderProcessingLink: LinkField;
  };
};

const MarketplaceCheckout = (props: MarketplaceCheckoutProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useI18n();
  const checkout = useAppSelector((state) => state.checkout);
  const {
    paymentMethods,
    billingAddress,
    paymentMethodStatus,
    checkoutStage,
    selectedSource,
    checkoutDataStatus,
    checkoutId,
  } = checkout;
  const drInstance = useDigitalRiverInstance();
  const cart = useAppSelector((state) => state.cart);
  const cartItemData = useCartData();
  const cartItems = cartItemData?.cartItems;
  const cartId = cartItemData?.cartSummary.cartId as string;
  const router = useRouter();
  const { action } = router.query;
  const dataExists =
    cart.cartItemsDataStatus === 'succeeded' && cart.cartItemsDataStatus === 'succeeded';

  const { data: session, status } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const [hasWarning, setHasWarning] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  useEffect(() => {
    if (status === 'loading') {
      return;
    }

    dispatch(getCountries());
    dispatch(getPaymentMethods({ bearerToken: bearerToken }));
  }, [dispatch, bearerToken, status]);

  useEffect(() => {
    if (!dataExists) {
      return;
    }

    if (status === 'loading') {
      return;
    }

    if (paymentMethodStatus === 'succeeded') {
      if (paymentMethods.length > 0) {
        dispatch(setCheckoutStage('payment-method'));
      } else {
        dispatch(setCheckoutStage('payment-billing'));
      }
    }

    if (checkoutId == null && selectedSource !== null) {
      console.log(selectedSource.source.id);
      dispatch(createCheckoutSession({ store: 'Avidlink', bearerToken: bearerToken }));
    }
  }, [
    checkoutId,
    dataExists,
    dispatch,
    paymentMethodStatus,
    paymentMethods,
    bearerToken,
    status,
    selectedSource,
  ]);

  useEffect(() => {
    if (
      selectedSource == null ||
      cartItemData?.cartSummary?.cartId == null ||
      checkoutId == null ||
      drInstance == null
    ) {
      return;
    }

    if (status === 'loading') {
      return;
    }

    dispatch(
      updateCheckout({
        checkoutId: checkoutId ?? '',
        sourceId: selectedSource.source.id,
        bearerToken: bearerToken,
        cartId: cartId,
      })
    );
  }, [
    dispatch,
    selectedSource,
    checkoutId,
    cartItemData?.cartSummary?.cartId,
    bearerToken,
    status,
    cartId,
    checkout.paymentSessionId,
    drInstance,
    t,
  ]);

  useEffect(() => {
    if (checkoutDataStatus === 'succeeded' && selectedSource !== null) {
      dispatch(setCheckoutStage('confirmation'));
    }
  }, [checkoutDataStatus, dispatch, selectedSource]);

  useEffect(() => {
    if (action === 'clearSources') {
      dispatch(setSelectedSource(null));
      removeSavedExpressPayment();
    }
  }, [action, dispatch]);

  useEffect(() => {
    if (!hasWarning) {
      return;
    }
    if (alertMessage !== t('Payment_Method_Disallowed')) {
      return;
    }
    if (cartItemData?.containsRecurringPlans) {
      return;
    }
    setHasWarning(false);
    setAlertMessage('');
  }, [alertMessage, cartItemData?.containsRecurringPlans, hasWarning, t]);

  return (
    <div id="checkout--marketplace" className={`${!dataExists ? 'h-96' : ''} mt-4 text-sm`}>
      <div className={`${hasWarning ? '' : 'hidden'} mb-4`}>
        <AlertBox
          containerClass="bg-warning-light border-warning text-warning [&>div>.dismisable-icon]:text-almost-black p-4 flex w-fit justify-center justify-self-center items-baseline md:items-center border rounded space-x-2 col-span-12 lg:col-span-8 lg:col-start-3"
          alertMessage={alertMessage}
          alertType="warning"
          isDismissible={false}
          showAlertBox={true}
          displayIcon={true}
        />
      </div>
      {!dataExists && <Spinner color="#20BBD0" />}
      {dataExists && (
        <div className="relative m-auto after:clear-both">
          {(checkoutStage === 'payment-method' ||
            checkoutStage === 'payment-billing' ||
            checkoutStage === 'mp-paypal-billing') && (
            <MarketplacePaymentMethods
              paymentMethods={paymentMethods}
              paymentMethodStatus={paymentMethodStatus}
              selectedSource={selectedSource as CustomSource | null}
              billingAddress={billingAddress as PaymentAddress | null}
              cartItems={cartItems as CartItem[]}
              continueShoppingLink={props.fields.continueShoppingLink}
              checkoutStage={checkoutStage}
              setHasWarning={setHasWarning}
              setAlertMessage={setAlertMessage}
            />
          )}
          {checkoutStage === 'confirmation' && (
            <MarketplaceCheckoutSummary
              cart={cart}
              cartItemData={cartItemData}
              continueShoppingLink={props.fields.continueShoppingLink}
              checkout={checkout}
              eula={props.fields.eula}
              thirdPartyTerms={props.fields.thirdPartyTerms}
              orderProcessingLink={props.fields.orderProcessingLink}
              link={props.fields.link}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default MarketplaceCheckout;
