import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useI18n } from 'next-localization';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ModalProps = {
  show: boolean;
  children?: JSX.Element;
  title?: string;
  titleClassName?: string;
  useModalBackdrop?: boolean;
  onClose: () => void;
  className?: string;
  rootId: string;
};

const Modal = ({
  show,
  onClose,
  children,
  title,
  titleClassName,
  useModalBackdrop,
  className,
  rootId,
}: ModalProps): JSX.Element | null => {
  const [isClient, setIsClient] = useState(false);
  const { t } = useI18n();

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (show && typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [show]);

  const onClickCloseHandler = (e: React.UIEvent): void => {
    e.preventDefault();
    onClose();
  };

  const innerContainerCssClasses = `bg-white flex flex-col p-8 justify-center z-50 rounded
   ${className ?? 'w-350'}
   `;

  const titleContent = title ? (
    <div className="flex justify-between pb-2 ">
      <h4 className={titleClassName ?? ''}>{title}</h4>
      <div>
        <button type="button" onClick={onClickCloseHandler}>
          <FontAwesomeIcon icon={faXmark} className="text-black w-full" />
          <span className="sr-only">{t('Close')}</span>
        </button>
      </div>
    </div>
  ) : null;

  const modalContent = show ? (
    <div className="fixed flex items-center justify-center top-0 w-full h-100vh z-50">
      {useModalBackdrop && (
        <div className="modal-backdrop absolute top-0 bg-avid-backdrop h-100vh opacity-50 w-full z-10" />
      )}
      <div className={innerContainerCssClasses}>
        {titleContent}
        {children}
      </div>
    </div>
  ) : null;

  if (isClient) {
    const modalRoot = document.getElementById(rootId);
    if (modalRoot) {
      return ReactDOM.createPortal(modalContent, modalRoot);
    }
  } else {
    return null;
  }

  return null;
};

export default Modal;
