import {
  AtomicResultList,
  AtomicSearchInterface,
  buildSearchEngine,
  buildContext,
  AtomicSortDropdown,
  AtomicSortExpression,
  Result,
  AtomicLayoutSection,
  AtomicDidYouMean,
  AtomicSearchLayout,
  AtomicFacetManager,
  AtomicQuerySummary,
  AtomicLoadMoreResults,
  AtomicBreadbox,
  AtomicFacet,
} from '@coveo/atomic-react';

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import {
  ProductPricingData,
  ProductPricingDataUnit,
} from 'lib/commerce/product/model/product-models';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { TAILWIND, SCREEN_LARGE, useScreenSize } from 'lib/hooks/use-screen-size';
import { useI18n } from 'next-localization';
import { addCartItem, fetchCartItems } from 'components/cart/cart-slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import dynamic from 'next/dynamic';
import { getProductVariations } from 'lib/commerce/product/product-api';
import { getDiscountValue } from 'lib/commerce/product/price-helpers';
import MarketplaceTemplate from 'components/search/templates/MarketplaceTemplate';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import {
  facetStyles,
  breadCrumbsStyles,
  resultsStyles,
  searchBoxStyles,
} from 'components/global-navigation/marketplace/search-styles';
import { getBearerToken } from 'lib/authentication/account-provider';
const NoResults = dynamic(() => import('components/atomic-components/NoResults'));
const QueryError = dynamic(() => import('components/atomic-components/QueryError'));
const Spinner = dynamic(() => import('components/spinner/Spinner'));

type PricesData = {
  [index: string]: {
    pricesArray: ProductPricingDataUnit[];
    discountValue?: number;
  } | null;
};
type MarketplaceResult = Result & ProductPricingData;

const MarketplaceSearch: React.FC<{ scrollPosition: Record<string, number> | null }> = ({
  scrollPosition,
}): JSX.Element => {
  const [hydrate, setHydrate] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [resultsLength, setResultsLength] = useState(0);
  const dispatch = useAppDispatch();
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode) || 'USD';
  const navigationState = useAppSelector((state) => state.navigation);
  const screenSize = useScreenSize();
  const isMobileView = screenSize.width < TAILWIND.sm;
  const [showFacets, setShowFacets] = useState(false);
  const [showFacetsPanel, setShowFacetsPanel] = useState(false);
  const { t } = useI18n();
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  useEffect(() => {
    setShowFacetsPanel(!isMobileView);
    setShowFacets(isMobileView);
  }, [isMobileView]);

  const buyHandler = (productId: string, setIsInCart: Dispatch<SetStateAction<boolean>>): void => {
    dispatch(addCartItem({ sku: productId, quantity: 1, bearerToken: bearerToken }))
      .unwrap()
      .then(() => {
        setIsInCart(true);
      })
      .catch((error) => {
        console.log(
          `Marketplace Search: >> Can't add to cart, product ${productId}: ${error.message}`
        );
        setIsInCart(false);
      });
  };

  const ResultTemplateFunction = (result: Result): JSX.Element => {
    return (
      <div className="search-result-wrapper">
        <MarketplaceTemplate
          result={result}
          buyHandler={buyHandler}
          currencySymbol={t(`Currency_Symbol_${currencyIsoCode}`)}
          t={t}
          scrollPosition={scrollPosition}
        />
      </div>
    );
  };

  const onClickFacetHandler = (e: React.UIEvent): void => {
    !isMobileView &&
      document.querySelectorAll('atomic-facet').forEach((facet): void => {
        if (facet.field === (e.target as HTMLAtomicFacetElement).field) {
          return;
        }
        facet.isCollapsed = true;
      });
  };

  const resetSearch = (): void => {
    window.location.hash = '';
    setShowFacetsPanel(false);
  };

  const searchEngine = useMemo(() => {
    setShowSpinner(true);
    return buildSearchEngine({
      configuration: {
        accessToken:
          process.env.COVEO_ACCESS_TOKEN && process.env.COVEO_ACCESS_TOKEN.length > 0
            ? process.env.COVEO_ACCESS_TOKEN
            : '',
        organizationId:
          process.env.COVEO_ORGANIZATION && process.env.COVEO_ORGANIZATION.length > 0
            ? process.env.COVEO_ORGANIZATION
            : '',
        search: {
          pipeline:
            process.env.COVEO_PIPELINE_MARKETPLACE &&
            process.env.COVEO_PIPELINE_MARKETPLACE.length > 0
              ? process.env.COVEO_PIPELINE_MARKETPLACE
              : '',
          preprocessSearchResponseMiddleware: async (response) => {
            setShowSpinner(true);
            const prices: PricesData = {};
            const pricesCalls = response.body.results.map((result: MarketplaceResult) => {
              const productId =
                (result.raw?.sfid as string) ?? (result.raw?.sfvariationid as string);
              if (!productId) {
                return Promise.resolve();
              }
              return getProductVariations({ productId, currencyIsoCode })
                .then((productPricing) => {
                  const pricesArray = productPricing.data.variants.map((variant) => variant.price);
                  prices[productId] = {
                    pricesArray,
                    discountValue: getDiscountValue(pricesArray),
                  };
                })
                .catch((error) => {
                  console.log(
                    `Marketplace Search: >> Can't get price for product ${productId}: ${error.message}`
                  );
                  prices[productId] = null;
                });
            });
            await Promise.all(pricesCalls);
            let cartItemsIds: string[] | null = null;
            try {
              const cartItemData = await dispatch(
                fetchCartItems({ bearerToken: bearerToken })
              ).unwrap();
              cartItemsIds = cartItemData.data.cartItems.map(
                (item): string => item.cartItem?.productId
              );
            } catch (e) {
              console.log(`Marketplace Search: >> Can't get cart items: `, e);
            }
            response.body.results.forEach((result: MarketplaceResult) => {
              const prId = (result.raw?.sfid as string) ?? (result.raw?.sfvariationid as string);
              result.raw.priceData = prices[prId] || null;
              result.raw.isInCart =
                prices[prId]?.pricesArray.length === 1 &&
                cartItemsIds?.includes(prices[prId]?.pricesArray[0].productId ?? '');
            });
            setResultsLength(response.body.results.length);
            setShowSpinner(false);
            return response;
          },
        },
      },
    });
  }, [bearerToken, currencyIsoCode, dispatch]);

  useEffect(() => {
    setHydrate(true);
    buildContext(searchEngine).add('IsAuthenticated', session ? 'True' : 'False');
  }, [session, searchEngine]);

  return (
    <div
      className={`marketplace-search-page pt-2 pb-10 ${
        showSpinner || (isMobileView && showFacetsPanel)
          ? 'h-[calc(100vh-90px)] overflow-hidden'
          : ''
      }`}
    >
      {showSpinner && <Spinner color="#20BBD0" />}
      {hydrate && searchEngine && (
        <AtomicSearchInterface
          engine={searchEngine}
          fieldsToInclude='["sfid","sfvariationid","z95xtemplate","shoplink","searchdescription","productcategory","purchasetype","searchtitle","searchimageurl","z95xtemplatename","documenttype","sfrecordtypename","sfdatacategorysoftware","sfisvisibleinpkb","sfurlname","language", "company"]'
          analytics={true}
          language={navigationState.currentLanguage ?? 'en'}
          languageAssetsPath="/searchlabels"
          localization-compatibility-version="v4"
        >
          <AtomicSearchLayout>
            <AtomicLayoutSection
              section="main"
              className={`${screenSize.width < SCREEN_LARGE ? 'pt-3 mt-2' : ''}`}
            >
              <style>{facetStyles}</style>
              <AtomicLayoutSection
                id="facetsPanel"
                section={isMobileView ? `facets` : `horizontal-facets`}
                className={`bg-mp-background-neutralDark mb-4 rounded border border-mp-background-neutralDark 
                  absolute right-0 top-0 !min-h-screen z-[60] ${
                    isMobileView && showFacetsPanel
                      ? 'w-11/12 !block h-screen overflow-y-auto'
                      : 'w-0 overflow-hidden sm:overflow-visible'
                  } sm:static sm:z-0 sm:!min-h-fit sm:w-full`}
              >
                <div
                  className={`flex flex-col sm:flex-row px-4 py-4 bg-mp-background-neutralDark rounded max-h-[60px] sm:max-h-none`}
                >
                  <div className="sm:hidden text-white flex flex-row justify-between font-bold">
                    <div className="">
                      <FontAwesomeIcon
                        icon={faChevronLeft}
                        className="inline-block h-6 w-6 cursor-pointer !m-0"
                        onClick={(): void => setShowFacetsPanel(false)}
                      />
                      <span className={`ml-2 text-2xl`}>{t('MP_Filters')}</span>
                    </div>
                    <button
                      className={`px-3 bg-mp-background-neutralMedium text-base rounded ${
                        window.location.hash.length > 0
                          ? 'text-mp-txt-bright'
                          : 'text-mp-txt-neutralLight pointer-events-none'
                      }`}
                      onClick={resetSearch}
                    >
                      {t('MP_Reset')}
                    </button>
                  </div>
                  {resultsLength > 0 && (
                    <button
                      className={`text-white text-base font-bold bg-mp-background-neutralMedium border border-mp-background-neutralMedium 
                        hover:border-mp-txt-primary rounded py-1 px-2 mb-5 sm:mb-0 sm:mr-2 capitalize min-w-[110px] h-8
                        hidden sm:inline
                      `}
                      onClick={(): void => {
                        setShowFacets(!showFacets);
                      }}
                    >
                      {showFacets ? 'Hide ' : 'Show '}filters
                    </button>
                  )}
                  <style>{breadCrumbsStyles}</style>
                  <AtomicBreadbox className="pt-1 hidden sm:block" />
                </div>
                <AtomicFacetManager
                  className={`w-full flex flex-col sm:flex-row sm:flex-wrap sm:justify-start bg-mp-background-neutralDark
                   ${showFacets ? 'px-4 pb-4' : 'h-0 p-0 overflow-hidden'}`}
                >
                  <AtomicFacet
                    field="category"
                    label="Category"
                    className={`relative`}
                    isCollapsed={!isMobileView}
                    withSearch={false}
                    onClick={onClickFacetHandler}
                  ></AtomicFacet>
                  <AtomicFacet
                    field="filter"
                    label="Filter"
                    className={`relative`}
                    isCollapsed={!isMobileView}
                    withSearch={false}
                    onClick={onClickFacetHandler}
                  ></AtomicFacet>
                  <AtomicFacet
                    field="app"
                    label="App"
                    className={`relative`}
                    isCollapsed={!isMobileView}
                    withSearch={false}
                    onClick={onClickFacetHandler}
                  ></AtomicFacet>
                  <AtomicFacet
                    field="plugin"
                    label="Plugin"
                    className={`relative`}
                    isCollapsed={!isMobileView}
                    withSearch={false}
                    onClick={onClickFacetHandler}
                  ></AtomicFacet>
                  <AtomicFacet
                    field="company"
                    label="Company"
                    className={`relative`}
                    isCollapsed={!isMobileView}
                    withSearch={false}
                    onClick={onClickFacetHandler}
                  ></AtomicFacet>
                </AtomicFacetManager>
              </AtomicLayoutSection>
              <style>{searchBoxStyles}</style>
              {window.location.hash?.length > 0 && (
                <div className={`text-mp-txt-bright text-xl sm:hidden font-bold`}>
                  {t('MP_Results_Filtered')}
                </div>
              )}
              <AtomicLayoutSection section="status" className="flex justify-between relative">
                <AtomicQuerySummary className="text-white pt-3.5 text-base"></AtomicQuerySummary>
                <AtomicSortDropdown className="hidden sm:block">
                  <AtomicSortExpression
                    label="relevance"
                    className="text-white"
                    color="#fff"
                    expression="relevancy"
                  />
                  <AtomicSortExpression label="Date (older first)" expression="date ascending" />
                  <AtomicSortExpression label="Date (newer first)" expression="date descending" />
                </AtomicSortDropdown>
                <AtomicDidYouMean />
                <button
                  className={`sm:hidden text-mp-txt-bright px-3 py-1 absolute right-0 top-3
                  bg-mp-background-neutralMedium rounded !m-0 text-base font-bold`}
                  onClick={(): void => setShowFacetsPanel(true)}
                >
                  {t('MP_Filter')}
                </button>
              </AtomicLayoutSection>

              <AtomicLayoutSection section="results" className={``}>
                <style>{resultsStyles}</style>
                <AtomicResultList
                  display="grid"
                  template={ResultTemplateFunction}
                  imageSize="small"
                />
                <QueryError className="text-white" />
                <NoResults className="text-white" enableCancelLastAction={false}></NoResults>
              </AtomicLayoutSection>
              <AtomicLayoutSection section="pagination">
                <AtomicLoadMoreResults className="text-white"></AtomicLoadMoreResults>
              </AtomicLayoutSection>
            </AtomicLayoutSection>
          </AtomicSearchLayout>
        </AtomicSearchInterface>
      )}
    </div>
  );
};

export default trackWindowScroll(MarketplaceSearch);
