import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSelector } from 'react-redux';
import { RootState } from 'lib/store/store';
import { getCartId } from 'components/cart/cart-provider';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { placeOrder } from 'components/checkout/checkout-slice';
import { useRouter } from 'next/router';
import { AnalyticsProductItem, pushAddPurchaseEvent } from 'lib/google-analytics/commerce';
import { useCurrencyConversionRates } from 'lib/commerce/webstore/webstore-hooks';
import { CurrencyData } from 'lib/commerce/webstore/model/webstore-models';
import { PlaceOrderData } from 'components/checkout/checkout-models';
import AlertBox from 'components/alert/AlertBox';
import { useEffect, useMemo, useState } from 'react';
import { useI18n } from 'next-localization';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';
import useDigitalRiverInstance from '../dr-elements-hook';
import { SfResponse } from 'lib/common/model/common-models';

type PlaceOrderLinkProps = {
  label: string;
  link: LinkField;
  orderProcessingLink: LinkField;
  acceptedTerms: boolean;
  errorMessage?: string;
};

const DisabledButton = ({
  label,
  hasError,
  errorMessage,
}: {
  label: string;
  hasError: boolean;
  errorMessage: string;
}): JSX.Element => {
  return (
    <>
      <div className={`${hasError ? '' : 'hidden'} mb-8`}>
        <AlertBox
          containerClass="bg-danger-light border-danger text-danger [&>div>.dismisable-icon]:text-almost-black p-4 flex w-fit justify-center justify-self-center items-baseline md:items-center border rounded space-x-2 col-span-12 lg:col-span-8 lg:col-start-3"
          alertMessage={errorMessage}
          alertType="danger"
          isDismissible={false}
          showAlertBox={true}
          displayIcon={true}
        />
      </div>
      <button
        disabled={true}
        className="capitalize text-center font-bold text-lg text-white px-4 py-8.5px bg-base-normal rounded"
      >
        {label}
      </button>
    </>
  );
};

const PlaceOrderLink = (props: PlaceOrderLinkProps): JSX.Element => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { t } = useI18n();
  const checkout = useSelector((state: RootState) => state.checkout);
  const drInstance = useDigitalRiverInstance();
  const checkoutData = checkout.checkoutData;
  const checkoutDataStatus = checkout.checkoutDataStatus;
  const selectedSource = checkout.selectedSource;
  const checkoutUpdateError = checkout.updateCheckoutError;
  const currencyConversionRates = useCurrencyConversionRates();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(props.errorMessage ?? null);

  const cartItemData = useAppSelector((state) => state.cart.cartItemsData);
  const customerInfo = useAppSelector((state) => state?.customer?.customerData);
  const currency = cartItemData?.cartSummary.currencyIsoCode as string;
  const couponCode = cartItemData?.cartSummary?.coupons?.[0]?.couponCode;
  const cartValue = cartItemData?.cartSummary?.grandTotalAmount.toString() as string;
  const itemsArray = cartItemData?.cartItems.map((item, index) => {
    const cartItem = item.cartItem;
    return {
      id: cartItem.productId,
      sku: cartItem.productId,
      name: cartItem.name,
      price: cartItem.unitAdjustedPrice,
      discount: (parseFloat(cartItem.listPrice) - parseFloat(cartItem.salesPrice)).toFixed(2),
      variant: cartItem.productDetails.fields.XC_SubscriptionType__c ?? 'N/A',
      brand: cartItem.productDetails.fields.VendorName__c,
      index: index.toString(),
      quantity: cartItem.quantity,
      itemCategory: cartItem.productCategoryPaths.primary?.at(-1)?.name,
      itemCategory2: cartItem.productCategoryPaths.path1?.at(-1)?.name,
      itemCategory3: cartItem.productCategoryPaths.path2?.at(-1)?.name,
      itemCategory4: cartItem.productCategoryPaths.path3?.at(-1)?.name,
      productSku: cartItem?.productDetails?.sku,
    };
  });

  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const sendPlaceOrderRequest = async (): Promise<SfResponse<PlaceOrderData> | null> => {
    return await dispatch(
      placeOrder({
        checkoutId: checkout?.checkoutId ?? '',
        sourceId: selectedSource?.source.id ?? '',
        cartId: getCartId(),
        bearerToken: bearerToken,
      })
    )
      .unwrap()
      .then((resp) => resp)
      .catch(() => {
        setHasError(true);
        return null;
      });
  };
  const handleDRSCARequest = async (
    placeOrderResp: SfResponse<PlaceOrderData> | null
  ): Promise<SfResponse<PlaceOrderData> | null> => {
    const DRpaymentActionRequired = placeOrderResp?.data?.digitalRiverResponse?.errors
      ?.map((error) => error.code)
      .some((code) => code === 'additional_payment_action_required');
    if (!placeOrderResp || placeOrderResp?.success || !DRpaymentActionRequired) {
      return placeOrderResp;
    }

    const drAuthResponse = await drInstance?.handleNextAction({
      action: 'sca_required',
      data: {
        sessionId: checkout.paymentSessionId,
      },
    });

    if (!drAuthResponse?.status || drAuthResponse?.status !== 'complete') {
      setHasError(true);
      setErrorMessage(t('checkout_payment_sca_error'));
      return null;
    }
    return await sendPlaceOrderRequest();
  };

  useEffect(() => {
    if (checkoutUpdateError != null) {
      setHasError(true);
      setErrorMessage(
        checkoutUpdateError !== 'undefined' ? checkoutUpdateError : t('checkout_error')
      );
      return;
    }

    if (props.errorMessage) {
      setHasError(true);
      setErrorMessage(props.errorMessage);
      return;
    }

    setHasError(false);
    setErrorMessage(null);
  }, [checkoutUpdateError, props.errorMessage, selectedSource, t]);

  const onClickPlaceOrderHandler = async (event: React.MouseEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();
    const checkoutId = checkout.checkoutId;

    if (selectedSource == null || checkoutId == null) {
      return;
    }

    setHasError(false);
    setErrorMessage(null);

    const placeOrderResp = await sendPlaceOrderRequest();
    const response = await handleDRSCARequest(placeOrderResp);

    if (response == null || !response?.data || !response?.success || !response?.data?.orderId) {
      setHasError(true);
      response?.data.uimessage &&
        response.data.uimessage.length > 0 &&
        setErrorMessage(response?.data?.uimessage);
      return;
    }

    let redirectUrl = `${props.link.value.href}?id=${response.data.orderId}`;
    if (response.data.digitalRiverResponse?.fraudState != 'passed') {
      redirectUrl = `${props.orderProcessingLink.value.href}?id=${response.data.orderId}`;
    }

    pushAddPurchaseEvent(
      response.data.digitalRiverResponse,
      currency,
      currencyConversionRates?.rates[currency] as CurrencyData,
      cartValue,
      itemsArray as unknown as Array<AnalyticsProductItem>,
      couponCode,
      (customerInfo?.contactId as string) || ''
    );

    router.push(redirectUrl);
  };

  if (checkoutDataStatus != 'succeeded' || !props.acceptedTerms || selectedSource == null) {
    return (
      <DisabledButton
        label={props.label}
        hasError={hasError}
        errorMessage={errorMessage != null ? errorMessage : t('checkout_error')}
      />
    );
  }

  if (
    checkoutData?.digitalRiverResponse?.items == null ||
    checkoutData.digitalRiverResponse.items.length == 0
  ) {
    return (
      <DisabledButton
        label={props.label}
        hasError={hasError}
        errorMessage={errorMessage != null ? errorMessage : t('checkout_error')}
      />
    );
  }

  return (
    <>
      <div className={`${hasError ? '' : 'hidden'} mb-8`}>
        <AlertBox
          containerClass="bg-danger-light border-danger text-danger [&>div>.dismisable-icon]:text-almost-black p-4 flex w-fit justify-center justify-self-center items-baseline md:items-center border rounded space-x-2 col-span-12 lg:col-span-8 lg:col-start-3"
          alertMessage={errorMessage != null ? errorMessage : t('checkout_error')}
          alertType="danger"
          isDismissible={false}
          showAlertBox={true}
          displayIcon={true}
        />
      </div>
      <div onClick={(event): Promise<void> => onClickPlaceOrderHandler(event)}>
        {drInstance !== null && (
          <button
            id="place-order-btn"
            className="w-full text-center font-bold text-lg text-white bg-primary rounded px-4 py-8.5px"
          >
            {props.label}
          </button>
        )}
      </div>
    </>
  );
};

export default PlaceOrderLink;
