import {
  Field,
  LinkField,
  Text,
  RichText,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import AnchorLinkJumpTo from 'components/section-intro/AnchorLinkJumpTo';
import { ComponentProps } from 'lib/component-props';

export type SectionIntroProps = ComponentProps & {
  rendering: {
    dataSource: string;
  };
  fields: {
    anchorLinkText: Field<string>;
    title: Field<string>;
    subtitle: Field<string>;
    sectionHeading: Field<string>;
    description: Field<string>;
    ctaButton: LinkField;
    noFollow?: Field<boolean>;
  };
};

const SectionIntro = (props: SectionIntroProps): JSX.Element => {
  return (
    <>
      <AnchorLinkJumpTo
        anchorLinkText={props.fields.anchorLinkText}
        className="scroll-mt-16 snap-start"
      />
      <div className="max-w-794 mx-auto text-center mt-10 mb-8 2xl:mt-20 sm:my-5 lg:my-10">
        <Text
          tag="h2"
          field={props.fields.subtitle}
          className="h2 uppercase text-almost-black tracking-widest-plus mb-2"
        />
        <Text
          tag="div"
          field={props.fields.title}
          className="display-3 uppercase text-secondary tracking-widest-plus mb-10"
        />
        <RichText
          field={props.fields.description}
          className="rte text-xl leading-7.5 text-almost-black lg:text-2xl lg:leading-9 mb-10"
        />
        <Link
          field={props.fields.ctaButton}
          linkCustomizations={props.fields}
          className="mb-2 inline-block font-bold text-lg text-base-darkest leading-6.75 px-4 py-10px border-2 rounded border-base-darkest hover:cursor-pointer hover:text-white hover:bg-base-darker hover:border-base-darker active:bg-base-darkest active:border-base-darkest disabled:text-base-normal disabled:border-base-normal focus:outline-none focus-visible:ring-3 ring-primary ring-offset-3"
        />
        <Text
          tag="div"
          field={props.fields.sectionHeading}
          className="font-bold text-xl leading-7.5 text-almost-black lg:text-2xl lg:leading-9"
        />
      </div>
    </>
  );
};

export default withDatasourceCheck()(SectionIntro);
