import { useEffect, useMemo } from 'react';
import { useI18n } from 'next-localization';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { addCartItem, deleteCartItem } from 'components/cart/cart-slice';
import { ImageField, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';
import { pushViewItem, pushAddToCart, pushRemoveFromCart } from 'lib/google-analytics/commerce';
import { Price } from 'lib/commerce/product/model/product-models';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type ProductRecommendationsCardProps = {
  product: RecommendedProductDetails;
  prices: Price[];
  scrollPosition: Record<string, number> | null;
};

export type RecommendedProductDetails = {
  productId: string;
  externalProductImageThumbnailUrl: string;
  productimageThumbnail: ImageField;
  productUrl: string;
  productName: string;
  productSku: string;
  primaryCategory: string;
  additionalCategory1: string;
  additionalCategory2: string;
  additionalCategory3: string;
  term: string;
};

function ProductRecommendationsCard(props: ProductRecommendationsCardProps): JSX.Element {
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const price = props.prices.find((priceObj) => priceObj.currencyIsoCode === currencyIsoCode);

  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { cartItemsData } = useAppSelector((state) => state.cart);
  const isDiscounted = props.product !== null && price && price.unitPrice < price.listPrice;
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const cart = useAppSelector((state) => state.cart);
  const cartItem = cart?.cartItemsData?.cartSummary;
  const customerInfo = useAppSelector((state) => state?.customer?.customerData);

  const productInCart = useMemo(() => {
    return cartItemsData?.cartItems?.find((c) => c.cartItem.productId === props.product?.productId);
  }, [cartItemsData?.cartItems, props.product?.productId]);

  useEffect(() => {
    pushViewItem(
      currencyIsoCode ?? '',
      price?.listPrice ? price?.listPrice?.toString() : 'NA',
      {
        id: props.product?.productId ?? '',
        sku: props.product?.productId ?? '',
        name: props.product?.productName ?? '',
        price: price?.listPrice ? price?.listPrice?.toString() : 'NA',
        discount: 'NA',
        brand: 'NA',
        variant: props.product?.term ?? 'N/A',
        itemCategory: props.product?.primaryCategory,
        itemCategory2: props.product?.additionalCategory1,
        itemCategory3: props.product?.additionalCategory2,
        itemCategory4: props.product?.additionalCategory3,
        productSku: props.product?.productSku,
      },
      (customerInfo?.contactId as string) || ''
    );
  }, [
    props.product?.additionalCategory1,
    props.product?.additionalCategory2,
    props.product?.additionalCategory3,
    currencyIsoCode,
    price,
    props.product?.primaryCategory,
    props.product?.productId,
    props.product?.productName,
    props.product?.productSku,
    props.product?.term,
    customerInfo?.contactId,
  ]);

  const onClickAddToCartHandler = (): void => {
    const details = {
      id: props.product?.productId ?? '',
      sku: props.product?.productId ?? '',
      name: props.product?.productName ?? '',
      price: price?.listPrice ? price?.listPrice?.toString() : 'NA',
      discount: 'NA',
      brand: 'NA',
      variant: props.product?.term ?? 'N/A',
      itemCategory: props.product?.primaryCategory,
      itemCategory2: props.product?.additionalCategory1,
      itemCategory3: props.product?.additionalCategory2,
      itemCategory4: props.product?.additionalCategory3,
      productSku: props.product?.productSku,
    };

    if (productInCart) {
      dispatch(
        deleteCartItem({ cartItemId: productInCart.cartItem.cartItemId, bearerToken: bearerToken })
      );
      pushRemoveFromCart(
        currencyIsoCode ?? '',
        cartItem?.totalListPrice?.toString() ?? '',
        details,
        (customerInfo?.contactId as string) || ''
      );
    } else {
      dispatch(
        addCartItem({ sku: props.product?.productId ?? '', quantity: 1, bearerToken: bearerToken })
      );

      pushAddToCart(
        currencyIsoCode ?? '',
        price?.listPrice ? price?.listPrice?.toString() : 'NA',
        details
      );
    }
  };

  return (
    <div
      className={`border rounded w-full border-base-normal flex flex-col items-center p-6 text-almost-black bg-white relative`}
    >
      {props.product?.productUrl && props.product?.productUrl.length > 0 && (
        <a
          href={props.product?.productUrl ?? ''}
          className="text-center font-bold text-lg inline-block md:h-3/5"
        >
          <div className="min-h-126 overflow-hidden h-4/6 my-3">
            {props.product?.externalProductImageThumbnailUrl &&
              props.product?.externalProductImageThumbnailUrl !== '' && (
                <LazyLoadImage
                  src={props.product?.externalProductImageThumbnailUrl}
                  className="w-full"
                  alt={`${props.product?.productName} image`}
                  scrollPosition={props.scrollPosition}
                />
              )}
            {props.product?.productimageThumbnail &&
              props.product?.productimageThumbnail.value?.src !== '' &&
              !props.product?.externalProductImageThumbnailUrl && (
                <NextImage
                  field={props.product?.productimageThumbnail}
                  className="w-full"
                  alt={`${props.product?.productName} image`}
                />
              )}
          </div>
          <h3 className="font-bold text-lg min-h-20p text-center my-4 w-full leading-5.4 font-avidwalsheimbold hover:underline">
            {props.product?.productName}
          </h3>
        </a>
      )}
      <div className="text-xl text-center my-4 min-h-44">
        <div
          className={`${isDiscounted ? 'visible' : 'invisible'} text-neutral text-xs line-through`}
        >
          <span>
            {t(`Currency_Symbol_${currencyIsoCode}`)}
            {price?.listPrice}
          </span>
        </div>
        <div className="">
          {price?.unitPrice !== undefined && price?.unitPrice > 0 && (
            <span>
              {t(`Currency_Symbol_${currencyIsoCode}`)}
              {price?.unitPrice}
            </span>
          )}
          {price?.unitPrice !== undefined && price?.unitPrice == 0 && (
            <>
              <span className="product-price accent-2 tracking-narrow">
                {t('PricingCard_Free')}
              </span>
            </>
          )}
        </div>
      </div>
      <button
        onClick={(): void => {
          onClickAddToCartHandler();
        }}
        className="text-center font-bold text-base btn-primary py-10px px-3 my-4"
        data-id={props.product?.productId}
      >
        {productInCart ? t('Cart_In_Cart') : t('Cart_Add')}
      </button>
    </div>
  );
}

export default ProductRecommendationsCard;
