import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import {
  ColorClassesType,
  themeColors,
} from 'src/components/foundation/non-sitecore/themes/themes-options';

type DropdownMenuProps = {
  options: { value: string; label: string }[];
  defaultValue?: { value: string; label: string } | null;
  placeholder?: string;
  className?: string;
  id?: string;
  required?: boolean;
  requiredValidationMessage?: string;
  triggerErrorState?: boolean;
  passValueToParentFunction: (value: string) => void;
  colorClasses?: ColorClassesType;
  isEditable?: boolean;
};

function DropdownMenu(props: DropdownMenuProps): JSX.Element | null {
  const {
    options,
    defaultValue,
    className,
    passValueToParentFunction,
    id,
    placeholder,
    required,
    requiredValidationMessage,
    triggerErrorState,
    isEditable,
  } = props;

  const colorClasses = props.colorClasses ?? themeColors.white;

  const [selectedOption, setSelectedOption] = useState(
    placeholder ? null : defaultValue || options[0]
  );
  const [displayPlaceholder, setDisplayPlaceholder] = useState(true);
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(options.length);

  const selectOptionHandler = (option: { value: string; label: string }): void => {
    setIsOpen(false);
    setDisplayPlaceholder(false);
    if (selectedOption == null || selectedOption?.value !== option.value) {
      setSelectedOption(option);
      passValueToParentFunction(option.value);
    }
  };

  useEffect(() => {
    if (defaultValue == null) {
      return;
    }

    passValueToParentFunction(defaultValue.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultValue == null) {
      return;
    }

    setSelectedOption(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  if (options.length <= 0) {
    return null;
  }

  return (
    <div
      className={`${className} ${colorClasses.general.textMainColorClass} ${
        isEditable === false && 'pointer-events-none'
      } flex flex-col`}
    >
      {id && (
        <select
          id={id}
          className="appearance-none peer absolute"
          onFocus={(): void => setIsOpen(true)}
        />
      )}

      <button
        className="flex justify-between items-center min-h-38px border border-base-dark rounded-sm pt-8px pb-6px pl-3 pr-17px outline-accent-cool"
        role="button"
        {...buttonProps}
        tabIndex={0}
        type="button"
      >
        <div>{displayPlaceholder && placeholder ? placeholder : selectedOption?.label}</div>
        <div>
          <FontAwesomeIcon
            icon={isOpen ? faSortUp : faSortDown}
            className={`my-auto relative pl-14px text-xl ${isOpen ? 'top-1' : 'bottom-1'}`}
          />
        </div>
      </button>
      <div className={`relative ${isOpen ? 'block' : 'hidden'}`}>
        <div
          role="menu"
          className={`flex flex-col border border-base-dark rounded-sm absolute z-5 w-full ${colorClasses.general.pageBgMain} border-t-0 max-h-200 overflow-y-auto`}
        >
          {options?.map((option, index) => (
            <a
              {...itemProps[index]}
              key={index}
              onClick={(e): void => {
                e.preventDefault();
                e.stopPropagation();
                selectOptionHandler({ value: option.value, label: option.label });
              }}
              className={`px-3 py-1 align-middle cursor-pointer ${
                colorClasses.dropDownMenu.itemHover
              } disabled:text-base-normal focus-visible:outline-none focus-visible:shadow-[inset_0_0_0_2px_rgba(0,0,0,0.3)] focus-visible:shadow-accent-cool ${
                selectedOption?.value === option.value
                  ? colorClasses.dropDownMenu.itemSelected
                  : colorClasses.dropDownMenu.itemNotSelected
              }`}
            >
              {option.label}
            </a>
          ))}
        </div>
      </div>
      {required && triggerErrorState && (
        <p className="mt-1 text-xs text-danger">{requiredValidationMessage || ''}</p>
      )}
    </div>
  );
}

export default DropdownMenu;
