import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';

import { useAppSelector } from 'lib/store/hooks';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import Spinner from 'components/spinner/Spinner';
import { OrderConfirmationSurveyItem } from 'src/pages/api/order/models/order-confirmation-survey';
import { getSitecoreProductDetails } from 'lib/commerce/product/product-api';
import { getSitecoreOrderSurvey } from 'components/order/order-api';

const OrderConfirmationSurvey = (): JSX.Element => {
  const orderDetails = useAppSelector((state) => state.order.orderDetailsData);
  const [orderProductItemIds, setOrderProductItemIds] = useState<Array<string>>([]);
  const [survey, setSurvey] = useState<OrderConfirmationSurveyItem>();
  const [isLoading, setIsLoading] = useState(true);
  const contextLanguage = 'en';

  useEffect(() => {
    const fetchProducts = async (): Promise<void> => {
      if (orderDetails == null) {
        return;
      }

      const sitecoreProductItemIds: string[] = [];
      for (let i = 0; i < orderDetails.orderItems.length; i++) {
        const productId = orderDetails.orderItems[i].product.productId;
        const result = await getSitecoreProductDetails(productId, contextLanguage);

        if (result == null || result.length == 0) {
          continue;
        }

        sitecoreProductItemIds.push(result[0].id);
      }

      if (sitecoreProductItemIds.length == 0) {
        setIsLoading(false);
        return;
      }

      setOrderProductItemIds(sitecoreProductItemIds);
    };

    fetchProducts();
  }, [orderDetails]);

  useEffect(() => {
    if (orderProductItemIds == null || orderProductItemIds.length == 0) {
      return;
    }

    const fetchSurvey = async (): Promise<void> => {
      for (let i = 0; i < orderProductItemIds.length; i++) {
        const result = await getSitecoreOrderSurvey(orderProductItemIds[i], contextLanguage);
        if (result == null || result.length == 0) {
          continue;
        }

        setSurvey(result[0]);
        break;
      }

      setIsLoading(false);
    };

    fetchSurvey();
  }, [orderProductItemIds]);

  return (
    <>
      {(isLoading || survey != null) && (
        <div>
          <div className="border-t border-almost-black my-8 "></div>
          {isLoading && (
            <div className="h-20 relative rounded-4 overflow-hidden">
              <Spinner width={35} height={35} />
            </div>
          )}

          {!isLoading && survey != null && (
            <div className="border border-info bg-info-light rounded-4 p-4 text-center text-info">
              <div className="">
                <RichText field={survey.body} className="rte" />
              </div>
              <div className="font-bold">
                <NextLink field={survey.link.jsonValue} />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default OrderConfirmationSurvey;
