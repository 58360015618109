import {
  Field,
  ImageField,
  LinkField,
  RichText,
  Text,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { ComponentProps } from 'lib/component-props';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResultImage from 'components/atomic-components/ResultImage';

type ClickthroughBlockImageProps = ComponentProps & {
  fields: {
    heading: Field<string>;
    description: Field<string>;
    link: LinkField;
    image: ImageField;
    noFollow?: Field<boolean>;
    byPassNextJsImageOptimization?: Field<boolean>;
    imageCDNUrl?: Field<string>;
  };
  params: {
    Theme: string;
    Orientation: string;
    ImagePosition: string;
    Tier: string;
    layout: string;
    marginTop: string;
    marginBottom: string;
  };
  scrollPosition: Record<string, number> | null;
};

const GetClassFromTheme = (theme: string): string => {
  if (theme == null) {
    return '';
  }

  switch (theme.toLowerCase()) {
    case 'light':
      return 'bg-white text-almost-black border-base-normal';
    case 'dark':
      return 'bg-neutral-darkest text-white border-neutral-darkest';
    default:
      return 'bg-white text-almost-black border-base-normal';
  }
};

const GetClassFromOrientation = (orientation: string): string => {
  if (orientation == null) {
    return '';
  }

  switch (orientation.toLowerCase()) {
    case 'horizontal':
      return 'md:grid md:grid-cols-12 ';
    case 'vertical':
      return 'md:grid md:grid-cols-12 lg:flex';
    default:
      return 'md:grid md:grid-cols-12';
  }
};

const GetClassFromImagePosition = (imagePosition: string): string => {
  if (imagePosition == null) {
    return '';
  }

  switch (imagePosition.toLowerCase()) {
    case 'left':
      return 'order-first';
    case 'right':
      return 'md:order-last';
    default:
      return 'order-first';
  }
};

const GetClassFromTier = (tier: string): string => {
  if (tier == null) {
    return 'aspect-[2/1]';
  }

  switch (tier.toLowerCase()) {
    case 'lower tier':
      return 'aspect-[2/1]';
    case 'lower tier left rail':
      return 'aspect-[4/3]';
    case 'tier one':
      return 'aspect-[4/3]';
    default:
      return 'aspect-[2/1]';
  }
};

const GetClassFromMargin = (MarginType: string, Margin: string): string => {
  if (Margin == null) {
    return '';
  }

  switch (Margin.toLowerCase()) {
    case '0px':
      return `${MarginType}0`;
    case '4px':
      return `${MarginType}1`;
    case '8px':
      return `${MarginType}2`;
    case '16px':
      return `${MarginType}4`;
    case '24px':
      return `${MarginType}6`;
    case '32px':
      return `${MarginType}8`;
    case '40px':
      return `${MarginType}10`;
    case '48px':
      return `${MarginType}12`;
    case '64px':
      return `${MarginType}16`;
    case '80px':
      return `${MarginType}20`;
    default:
      return `${MarginType == 'mb-' ? 'mb-20' : ''}`;
  }
};

const ClickthroughBlockImage = (props: ClickthroughBlockImageProps): JSX.Element => {
  if (props.params == null) {
    return <div>Rendering parameters are null</div>;
  }

  const isEditing = isEditorActive();

  if (props.fields?.heading == null) {
    if (isEditing) {
      return <div>Invalid datasource</div>;
    }
    return <></>;
  }
  const { Theme, Orientation, ImagePosition, Tier } = props.params;
  const themeClass = GetClassFromTheme(Theme);
  const orientationClass = GetClassFromOrientation(Orientation);
  const imagePositionClass = GetClassFromImagePosition(ImagePosition);
  const tierClass = GetClassFromTier(Tier);
  const topmargin = props?.params?.marginTop
    ? `${GetClassFromMargin('mt-', props.params.marginTop)}`
    : '';
  const bottommargin = props?.params?.marginBottom
    ? `${GetClassFromMargin('mb-', props.params.marginBottom)}`
    : 'mb-20';

  return (
    <div
      className={`clickthrough-block-item max-w-content  flex flex-col sm:mb-10 ${
        Orientation == 'horizontal' ? 'px-2 lg:px-4 2xl:px-0' : ''
      } ${topmargin ? 'lg:' + topmargin : ''} ${bottommargin ? 'lg:' + bottommargin : ''} ${
        props?.params?.layout == null ? 'mx-auto' : ''
      }`}
    >
      <div
        className={`${themeClass} ${orientationClass} w-full flex flex-1 flex-col rounded ${
          Tier?.toLowerCase() === 'lower tier left rail' ? 'border-none' : 'border my-2 lg:my-4'
        }`}
      >
        <div
          className={`relative grid h-full lg:h-auto w-full ${
            props.fields.byPassNextJsImageOptimization?.value ? 'overflow-hidden' : ''
          } ${imagePositionClass} ${tierClass} ${
            Tier?.toLowerCase() === 'lower tier left rail'
              ? 'md:col-span-5'
              : 'md:col-span-7 xl:col-span-8'
          }`}
        >
          {!isEditing && props.fields.byPassNextJsImageOptimization?.value ? (
            <span className="box-border max-w-full block absolute object-cover min-w-full h-full">
              {props.fields.imageCDNUrl?.value ? (
                <ResultImage
                  src={props.fields.imageCDNUrl.value ?? ''}
                  alt={props.fields.image?.value?.alt as string}
                  className="min-w-full min-h-full col-span-12 w-[100%] object-center lg:col-span-8 xl:col-span-7 object-cover"
                  scrollPosition={props.scrollPosition}
                />
              ) : (
                props.fields.image?.value?.src != null &&
                props.fields.image?.value?.src != '' && (
                  <ResultImage
                    src={props.fields.image?.value?.src ?? ''}
                    alt={props.fields.image?.value?.alt as string}
                    scrollPosition={props.scrollPosition}
                    className={`min-w-full min-h-full object-cover rounded-t ${
                      Tier?.toLowerCase() === 'lower tier left rail' ? 'rounded' : 'md:rounded-none'
                    }
                ${Orientation === 'vertical' && 'md:rounded-l lg:rounded-none lg:rounded-t'} 
                ${
                  Orientation === 'horizontal' && ImagePosition === 'right'
                    ? 'md:rounded-r'
                    : 'md:rounded-l'
                } ${isEditing ? 'max-h-full' : ''}`}
                  />
                )
              )}
            </span>
          ) : (
            <NextImage
              field={props.fields.image}
              layout="fill"
              className={`object-cover rounded-t ${
                Tier?.toLowerCase() === 'lower tier left rail' ? 'rounded' : 'md:rounded-none'
              }
            ${Orientation === 'vertical' && 'md:rounded-l lg:rounded-none lg:rounded-t'} 
            ${
              Orientation === 'horizontal' && ImagePosition === 'right'
                ? 'md:rounded-r'
                : 'md:rounded-l'
            } ${isEditing ? 'max-h-full' : ''}`}
            />
          )}
        </div>
        <div
          className={`md:flex flex-col text-left flex-1 flex-grow ${
            Tier?.toLowerCase() === 'lower tier left rail'
              ? 'p-0 pt-8 md:p-0 md:pl-8 md:col-span-7'
              : 'p-8 pb-22 md:px-6 lg:px-8 2xl:p-8  md:col-span-5 xl:col-span-4'
          } ${Orientation === 'horizontal' ? 'justify-center' : ''}`}
        >
          <Text
            tag="h4"
            field={props.fields.heading}
            className="text-left align-top mb-3 2xl:mb-4"
          />
          <RichText
            field={props.fields.description}
            className={`rte body mb-2 2xl:mb-4 ${Orientation === 'horizontal' ? '' : 'flex-1'}`}
          />
          <Link
            field={props.fields.link}
            linkCustomizations={props.fields}
            className={`${
              props.params.Theme?.toLowerCase() === 'light'
                ? 'btn-link-underline'
                : 'btn-link-underline-dark'
            } mr-auto whitespace-normal px-0`}
            hideText
          >
            <span>{props.fields.link?.value?.text}</span>
            <FontAwesomeIcon icon={faChevronRight} className="text-xs pl-4" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withDatasourceCheck()<ClickthroughBlockImageProps>(ClickthroughBlockImage);
