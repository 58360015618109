import { absoluteMediaUrl } from 'lib/utils/sitecore-media-utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type ResultImageProps = {
  alt: string;
  src: string;
  onClick?: () => void;
  className?: string;
  width?: number;
  scrollPosition?: Record<string, number> | null;
};

const ResultImage: React.FC<ResultImageProps> = (props) => {
  const imageSrc = absoluteMediaUrl(props.src);
  return (
    <LazyLoadImage
      src={imageSrc}
      alt={props.alt}
      onClick={props.onClick}
      className={`result-image ${props.className}`}
      width={props.width}
      scrollPosition={props.scrollPosition}
    />
  );
};

export default ResultImage;
