import { isFeatureEnabled } from 'lib/feature-flags/feature-flags';
import { Source } from './checkout-models';
import { GetCartItemsData } from 'components/cart/cart-models';
import { CartRuleSimplified, CartRuleSitecore } from 'src/renderings/cart/CartSummary';

const disallowedFundingSources = ['debit', 'prepaid'];

const isDebitCardsCheckingEnabled = isFeatureEnabled(
  'debit-cards-checking-on-checkout-SITECORE-295'
);

export const isPMFundingSourceDisallowed = ({
  source,
  cartItemData,
}: {
  source: Source | null;
  cartItemData: GetCartItemsData | null;
}): boolean => {
  if (!isDebitCardsCheckingEnabled) {
    return false;
  }
  if (!cartItemData?.containsRecurringPlans) {
    return false;
  }

  const fundingSource = source?.creditCard?.fundingSource || source?.googlePay?.fundingSource;

  if (fundingSource && disallowedFundingSources.find((item) => item === fundingSource)) {
    return true;
  }

  return false;
};

export const getCartItemsLimits = (sitecoreArray: CartRuleSitecore[]): CartRuleSimplified[] => {
  const limitsList: CartRuleSimplified[] = [];

  sitecoreArray.forEach((item) => {
    const min = item.minCartQuantity.value ? Number(item.minCartQuantity.value) : 1;
    const max = item.maxCartQuantity.value ? Number(item.maxCartQuantity.value) : 99;

    item.product.targetItems.forEach((productItem) => {
      limitsList.push({
        productId: productItem.productId.value,
        min,
        max,
      });
    });
  });
  return limitsList;
};
