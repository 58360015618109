import { useContext, useEffect } from 'react';
import { Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useAppDispatch } from 'lib/store/hooks';
import CoveoSearchEngineContext from 'lib/coveo/coveo-context';
import { setCoveoQuery } from 'components/search/coveo-query-slice';
import { SearchEngine } from '@coveo/headless';
import dynamic from 'next/dynamic';
import { ReferenceStringItem } from 'components/foundation/common/reference-string/reference-string-props';

const BaseSearchResults = dynamic(() => import('../../components/search/BaseSearchResults'));

type QueryCustomSearchProps = ComponentProps & {
  fields: {
    coveoQuery: ReferenceStringItem;
    labels: { value: string };
  };
};

const Default = (props: QueryCustomSearchProps): JSX.Element => {
  const coveoQuery = props.fields?.coveoQuery?.fields?.value?.value;
  const searchEngine = useContext(CoveoSearchEngineContext) as unknown as SearchEngine;
  const dispatch = useAppDispatch();
  const itemListName = 'Custom Search Results';
  const itemListId = `CustomSearchResults_0`;
  const searchHubName = 'AvidComListingSearch';

  useEffect(() => {
    dispatch(setCoveoQuery({ query: coveoQuery, searchHub: searchHubName }));

    return () => {
      dispatch(setCoveoQuery({ query: null, searchHub: null }));
    };
  }, [coveoQuery, dispatch]);

  return (
    <>
      {searchEngine && (
        <BaseSearchResults
          listId={itemListId}
          listName={itemListName}
          searchEngine={searchEngine}
          labels={props.fields?.labels ?? { value: '' }}
          leftRailBottomConntent={
            <Placeholder name="custom-search-rail-bottom" rendering={props.rendering} />
          }
        />
      )}
    </>
  );
};

export default withDatasourceCheck()(Default);
