import { Field, LinkField, RichText, isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import { ProductItem } from 'components/foundation/commerce/models/product-item';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import { BaseCarouselProps } from 'components/carousel/BaseCarouselProps';
import InlineSpinner from 'components/spinner/InlineSpinner';
import { useCartData } from 'components/cart/cart-hooks';
import { usePrice } from 'lib/commerce/product/price-hook';
import { ComponentProps } from 'lib/component-props';
import { AnalyticsProductItem } from 'lib/google-analytics/commerce';
import { useAppSelector } from 'lib/store/hooks';
import { useI18n } from 'next-localization';
import { useEffect } from 'react';

export type ProductListCarouselProductCardProps = BaseCarouselProps &
  ComponentProps & {
    fields: {
      product: Array<ProductItem>;
      link: LinkField;
      badge: Field<string>;
      noFollow?: Field<boolean>;
    };
    fromCloneListId: string;
    fromCloneListName: string;
    fromCloneIndex: number;
    fromCloneOnPriceCallback: (analyticsProduct: AnalyticsProductItem) => void;
    fromCloneOnSelectItemCallback: (analyticsProduct: AnalyticsProductItem) => void;
  };

const ProductListCarouselProductCard = (
  props: ProductListCarouselProductCardProps
): JSX.Element => {
  const editorActive = isEditorActive();
  const hasBadge = props.fields?.badge?.value != null && props.fields.badge.value.length > 0;
  const hasProduct = props.fields?.product != null && props.fields?.product.length > 0;

  const largeVariant =
    props.params?.MaxItemsPerSlideOnDesktop != null &&
    props.params?.MaxItemsPerSlideOnDesktop == '4';

  const { t } = useI18n();
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);

  const price = usePrice(props.fields.product[0]?.fields.productId.value, currencyIsoCode);
  const cart = useCartData();

  useEffect(() => {
    if (price == null || !hasProduct) {
      return;
    }

    const productItem = props.fields.product[0];

    if (currencyIsoCode == null || cart?.cartSummary == null) {
      return;
    }

    if (props.fromCloneOnPriceCallback == null) {
      return;
    }

    const analyticsProduct: AnalyticsProductItem = {
      id: productItem.fields.productId.value,
      sku: productItem.fields.productCode.value,
      name: productItem.fields.productName.value,
      price: price.listPrice.toString(),
      discount: (price.listPrice - price.unitPrice).toString(),
      brand: productItem.fields.brand.value,
      variant: productItem?.fields?.term?.value ?? 'N/A',
      listId: props.fromCloneListId,
      listName: props.fromCloneListName,
      index: props.fromCloneIndex?.toString(),
      itemCategory: productItem.fields.primaryCategory[0]?.fields.categoryName?.value,
      itemCategory2: productItem.fields.additionalCategories[0]?.fields.categoryName?.value,
      itemCategory3: productItem.fields.additionalCategories[1]?.fields.categoryName?.value,
      itemCategory4: productItem.fields.additionalCategories[2]?.fields.categoryName?.value,
      productSku: productItem.fields.productCode.value,
    };

    props.fromCloneOnPriceCallback(analyticsProduct);
  }, [cart, currencyIsoCode, hasProduct, price, props]);

  if (!hasProduct) {
    return (
      <div className={`carousel-item flex p-4 ${editorActive ? '' : 'flex-1'}`}>
        <div className="flex flex-col flex-1 rounded bg-white border border-1 border-base-normal overflow-hidden relative">
          <div className="flex-1 relative">Select a Product to View</div>
        </div>
      </div>
    );
  }

  const onClickSelectItemHandler = (): void => {
    if (price == null || !hasProduct) {
      return;
    }

    const productItem = props.fields.product[0];

    if (currencyIsoCode == null || cart?.cartSummary == null) {
      return;
    }

    if (props.fromCloneOnSelectItemCallback == null) {
      return;
    }

    const analyticsProduct: AnalyticsProductItem = {
      id: productItem.fields.productId.value,
      sku: productItem.fields.productCode.value,
      name: productItem.fields.productName.value,
      price: price.listPrice.toString(),
      discount: (price.listPrice - price.unitPrice).toString(),
      brand: productItem.fields.brand.value,
      variant: productItem?.fields?.term?.value ?? 'N/A',
      listId: props.fromCloneListId,
      listName: props.fromCloneListName,
      index: props.fromCloneIndex?.toString(),
      itemCategory: productItem.fields.primaryCategory[0]?.fields.categoryName?.value,
      itemCategory2: productItem.fields.additionalCategories[0]?.fields.categoryName?.value,
      itemCategory3: productItem.fields.additionalCategories[1]?.fields.categoryName?.value,
      itemCategory4: productItem.fields.additionalCategories[2]?.fields.categoryName?.value,
      productSku: productItem.fields.productCode.value,
    };

    props.fromCloneOnSelectItemCallback(analyticsProduct);
  };

  const product = props.fields.product[0];
  const linkText = product.fields.productName.value;

  return (
    <div className={`carousel-item flex p-4 ${editorActive ? '' : 'flex-1'}`}>
      <div className="flex flex-col flex-1 rounded bg-white border border-1 border-base-normal overflow-hidden relative">
        <NextLink
          className="flex-1 relative"
          field={props.fields?.link}
          linkCustomizations={props.fields}
          hideText={true}
          onClick={(): void => onClickSelectItemHandler()}
        >
          <div className="aspect-4/3 relative overflow-hidden">
            {hasProduct && (
              <NextImage field={product.fields?.productImage} layout="fill" editable={false} />
            )}
          </div>
          {(hasBadge || editorActive) && (
            <div className="absolute right-3 top-3 bg-info-light rounded-lg uppercase text-info px-2 py-2px text-14 font-bold">
              <RichText className="rte leading-none" field={props.fields?.badge} />
            </div>
          )}
          <div className={`text-center ${largeVariant ? 'p-3 pt-2' : 'p-2'}`}>
            <div className={`intro-text text-primary ${largeVariant ? 'pb-1' : 'text-base'}`}>
              {linkText}
            </div>
            {price != null && (
              <div className={`accent-3 tracking-medium ${largeVariant ? 'text-xl' : ''}`}>
                {price.listPrice !== price.unitPrice && (
                  <div className="text-base-dark text-sm line-through">
                    {t(`Currency_Symbol_${price?.currencyIsoCode}`)}
                    {price.listPrice}
                  </div>
                )}
                <div>
                  {t(`Currency_Symbol_${price?.currencyIsoCode}`)}
                  {price.unitPrice}
                </div>
              </div>
            )}
            {price == null && <InlineSpinner height={10} width={3} margin={2} />}
          </div>
        </NextLink>
      </div>
    </div>
  );
};

export default ProductListCarouselProductCard;
