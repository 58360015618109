import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { CartItemDetails, GetCartItemsData } from 'components/cart/cart-models';
import { updateCartItem, deleteCartItem } from 'components/cart/cart-slice';
import { useI18n } from 'next-localization';
import {
  AnalyticsProductItem,
  getProductCartIndex,
  pushAddToCart,
  pushRemoveFromCart,
} from 'lib/google-analytics/commerce';

import BaseItemQuantityNudger from './BaseItemQuantityNudger';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';
import { useEffect, useMemo, useState } from 'react';
import { NonOkResponse, SfResponse } from 'lib/common/model/common-models';
import { isNonOkResponse } from 'lib/common/common-http-client';

type CartItemQuantityNudgerProps = {
  cartItem: CartItemDetails;
  quantityLimits:
    | {
        productId: string;
        min: number;
        max: number;
      }
    | undefined;
  passHasWarningToParentFunc?: (value: boolean) => void;
  passWarningTextToParentFunc?: (value: string) => void;
};

const CartItemQuantityNudger = (props: CartItemQuantityNudgerProps): JSX.Element => {
  const { t } = useI18n();
  const cart = useAppSelector((state) => state.cart);
  const cartItem = props.cartItem;
  const dispatch = useAppDispatch();
  const cartUpdateItemStatus = useAppSelector((state) => state.cart.cartUpdateItemStatus);
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const [quantity, setQuantity] = useState<number>(parseInt(cartItem.quantity));
  const customerInfo = useAppSelector((state) => state?.customer?.customerData);

  const onClickRemoveItemHandler = async (cartItemId: string): Promise<void> => {
    dispatch(deleteCartItem({ cartItemId, bearerToken: bearerToken }));

    if (cart.currencyIsoCode == null || cart.cartSummaryData == null) {
      return;
    }

    pushRemoveFromCart(
      cart.currencyIsoCode,
      parseFloat(cartItem.salesPrice).toString(),
      convertToAnalyticsItem(cartItem, cart.cartItemsData, cartItem.quantity),
      (customerInfo?.contactId as string) || ''
    );
  };

  const onChangeQuantityHandler = async (newQuantity: number): Promise<void> => {
    if (cart.currencyIsoCode == null || cart.cartSummaryData == null) {
      return;
    }

    if (newQuantity < 1) {
      setQuantity(0);
      return;
    }
    const oldQantity = quantity;
    setQuantity(newQuantity);

    const updateResp = await dispatch(
      updateCartItem({
        cartItemId: cartItem.cartItemId,
        quantity: newQuantity,
        bearerToken: bearerToken,
      })
    );

    const resp = updateResp?.payload as SfResponse<void | CartItemDetails> | NonOkResponse;

    if (!resp) {
      setQuantity(oldQantity);
      return;
    }

    if (isNonOkResponse(resp)) {
      type errorObj = {
        error?: {
          message: string;
        };
      };
      const errorMessage = (resp.data as errorObj)?.error?.message;
      errorMessage && props.passHasWarningToParentFunc && props.passHasWarningToParentFunc(true);
      errorMessage &&
        props.passWarningTextToParentFunc &&
        props.passWarningTextToParentFunc(errorMessage);
      setQuantity(quantity);
      return;
    }

    if (newQuantity > quantity) {
      pushAddToCart(
        cart.currencyIsoCode ?? '',
        (
          parseFloat(cart.cartSummaryData?.totalProductAmountAfterAdjustments ?? '') +
          parseFloat(cartItem.salesPrice)
        ).toString(),
        convertToAnalyticsItem(cartItem, cart.cartItemsData)
      );
    } else {
      pushRemoveFromCart(
        cart.currencyIsoCode ?? '',
        parseFloat(cartItem.salesPrice).toString(),
        convertToAnalyticsItem(cartItem, cart.cartItemsData),
        (customerInfo?.contactId as string) || ''
      );
    }
    const cartItemUpdate = resp.data as CartItemDetails;
    setQuantity(parseInt(cartItemUpdate.quantity));
  };

  const convertToAnalyticsItem = (
    cartItem: CartItemDetails,
    cartData: GetCartItemsData | null,
    itemQuantity?: string
  ): AnalyticsProductItem => {
    const index = getProductCartIndex(cartItem.productId, cartData);

    return {
      id: cartItem.productId,
      sku: cartItem.productId,
      name: cartItem.productDetails.name,
      price: cartItem.listPrice.toString(),
      discount: (parseInt(cartItem.listPrice) - parseInt(cartItem.salesPrice)).toString(),
      brand: cartItem.productDetails.brand,
      variant: cartItem.productDetails.fields.XC_SubscriptionType__c ?? 'N/A',
      index: index.toString(),
      ...(itemQuantity && { quantity: itemQuantity }),
      itemCategory: cartItem.productCategoryPaths.primary?.at(-1)?.name,
      itemCategory2: cartItem.productCategoryPaths.path1?.at(-1)?.name,
      itemCategory3: cartItem.productCategoryPaths.path2?.at(-1)?.name,
      itemCategory4: cartItem.productCategoryPaths.path3?.at(-1)?.name,
      productSku: cartItem.productDetails.sku,
    };
  };

  useEffect(() => {
    setQuantity(parseInt(props.cartItem.quantity));
  }, [props.cartItem.quantity]);

  return (
    <div className="flex space-x-3">
      <p
        className="text-sm text-primary hover:cursor-pointer underline flex items-center"
        onClick={(): Promise<void> => onClickRemoveItemHandler(cartItem.cartItemId)}
      >
        {t('Cart_Remove')}
      </p>
      <BaseItemQuantityNudger
        key={`nudger${quantity}`}
        passValueToParentFunction={onChangeQuantityHandler}
        disableButton={cartUpdateItemStatus === 'loading'}
        quantity={quantity}
        minValue={props.quantityLimits?.min ?? undefined}
        maxValue={props.quantityLimits?.max ?? undefined}
        isReadOnly={false}
        passHasWarningToParentFunc={props.passHasWarningToParentFunc}
        passWarningTextToParentFunc={props.passWarningTextToParentFunc}
      />
    </div>
  );
};

export default CartItemQuantityNudger;
