import { buildSearchEngine } from '@coveo/atomic-react';
import { useMemo } from 'react';
import { pushCoveoSearchEvent } from 'lib/google-analytics/coveo-site-search';
import { Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { TaxonomyTermItem } from '../../components/category-listing-search/product-category-listing-models';
import dynamic from 'next/dynamic';
import { useI18n } from 'next-localization';
import { getLanguageName } from 'lib/navigation/navigation-utils';

const BaseSearchResults = dynamic(() => import('../../components/search/BaseSearchResults'));

type CustomSearchProps = ComponentProps & {
  fields: {
    contentType: {
      fields: TaxonomyTermItem;
    };
    labels: { value: string };
  };
};

const Default = (props: CustomSearchProps): JSX.Element => {
  const hasContentTypeFilter = props.fields?.contentType?.fields != null;
  const { locale } = useI18n();

  const searchEngine = useMemo(() => {
    return buildSearchEngine({
      configuration: {
        accessToken:
          process.env.COVEO_ACCESS_TOKEN && process.env.COVEO_ACCESS_TOKEN.length > 0
            ? process.env.COVEO_ACCESS_TOKEN
            : '',
        organizationId:
          process.env.COVEO_ORGANIZATION && process.env.COVEO_ORGANIZATION.length > 0
            ? process.env.COVEO_ORGANIZATION
            : '',
        search: {
          pipeline:
            process.env.COVEO_PIPELINE && process.env.COVEO_PIPELINE.length > 0
              ? process.env.COVEO_PIPELINE
              : '',
          searchHub: 'AvidComGlobalSearch',
        },
        analytics: {
          analyticsClientMiddleware: (...[, payload]) => {
            pushCoveoSearchEvent(payload);
            return payload;
          },
        },
        preprocessRequest: (request, clientOrigin) => {
          if (!hasContentTypeFilter) {
            return request;
          }

          const language = locale();
          if (language == null || language.length === 0) {
            return request;
          }

          if (clientOrigin == 'searchApiFetch') {
            const body = JSON.parse(request.body?.toString() ?? '');
            body.aq = `@documenttype==(${
              props.fields.contentType.fields.termName.value
            }) AND (@language==(${getLanguageName(language)}) OR (NOT @language))`;
            request.body = JSON.stringify(body);
          }

          return request;
        },
      },
    });
  }, [hasContentTypeFilter, props.fields?.contentType, locale]);

  const itemListName = 'Custom Search Results';
  const itemListId = `CustomSearchResults_0`;

  return (
    <>
      <BaseSearchResults
        listId={itemListId}
        listName={itemListName}
        searchEngine={searchEngine}
        labels={props.fields?.labels ?? { value: '' }}
        leftRailBottomConntent={
          <Placeholder name="custom-search-rail-bottom" rendering={props.rendering} />
        }
      />
    </>
  );
};

export default withDatasourceCheck()(Default);
