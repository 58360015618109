import { Field, ImageField, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { BrightcoveVideoProps } from 'components/video/brightcove-video-props';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { useState } from 'react';
import dynamic from 'next/dynamic';

const BaseEmbededVideo = dynamic(() => import('components/video/BaseEmbededVideo'), {
  ssr: false,
});

const BrightcoveVideoModalTrigger = dynamic(
  () => import('components/video/brightcove/BrightcoveVideoModalTrigger'),
  {
    ssr: false,
  }
);

const VideoPlayerIcon = dynamic(() => import('components/video/VideoPlayerIcon'), {
  ssr: false,
});

type VideoCarouselCardProps = {
  fields: {
    isembedded: Field<boolean>;
    BackgroundImage: ImageField;
    caption: Field<string>;
    videos: BrightcoveVideoProps[];
  };
};

const VideoCarouselCard = (props: VideoCarouselCardProps): JSX.Element => {
  const video = props?.fields?.videos[0];
  const videoId = video?.fields?.videoReference?.[0]?.fields?.ID?.value as string;
  const playerId = video?.fields?.player?.fields?.ID?.value as string;
  const isembedded = props?.fields?.isembedded.value;
  const videoThumbnail = video?.fields?.stillImage;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const editorActive = isEditorActive();
  return (
    props?.fields?.videos &&
    (isembedded ? (
      <section className={editorActive ? 'p-4  carousel-item' : 'w-full'}>
        <div className={`w-full relative aspect-video ${editorActive ? 'overflow-hidden' : ''}`}>
          <BaseEmbededVideo video={video} />
        </div>
        <div className="w-full mt-4 font-normal text-base text-center" data-isembedded={isembedded}>
          <Text field={props?.fields?.caption} />
        </div>
      </section>
    ) : (
      <section className={editorActive ? 'p-4  carousel-item' : 'w-full'}>
        <div className={`w-full relative aspect-video ${editorActive ? 'overflow-hidden' : ''}`}>
          <BrightcoveVideoModalTrigger
            videoId={videoId}
            playerId={playerId}
            isOpen={openModal}
            setOn={setOpenModal}
            openCtaClassName=""
          />
          <div className="before:content-[' '] before:z-10 before:absolute before:bg-imageOverlayBlack before:top-0 before:left-0 before:w-full before:h-full">
            {videoThumbnail ? (
              <NextImage
                field={videoThumbnail}
                layout="fill"
                className="pointer-events-none object-cover group-hover:opacity-75 mb-12 rounded"
              />
            ) : (
              <span className=" bg-base-lighter pointer-events-none object-cover group-hover:opacity-75 mb-12 rounded" />
            )}
            <div className="cursor-pointer z-20" onClick={(): void => setOpenModal(true)}>
              <VideoPlayerIcon className="text-80  text-white border-5 border-playIconBorderBlack bg-playIconBorderBlack rounded-full border-opacity-70" />
            </div>
          </div>
        </div>
        <div className="w-full mt-4 font-normal text-sm leading-5.3 text-center">
          <Text field={props?.fields?.caption} />
        </div>
      </section>
    ))
  );
};

export default VideoCarouselCard;
