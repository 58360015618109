import { BrightcoveVideoProps } from 'components/video/brightcove-video-props';
import { YoutubeVideoProps } from 'components/video/youtube-video-props';
import { useEffect, useState } from 'react';
import { useI18n } from 'next-localization';
import ReactDOM from 'react-dom';
import dynamic from 'next/dynamic';

const BrightcoveEmbededVideo = dynamic(
  () => import('components/video/brightcove/BrightcoveEmbededVideo'),
  {
    ssr: false,
  }
);

const YoutubeEmbededVideo = dynamic(() => import('components/video/youtube/YoutubeEmbededVideo'), {
  ssr: false,
});

const closeIcon = (): JSX.Element => {
  return (
    <img
      src="https://cdn.avid.com/avidcom/images/shared/icons/bright-cove-model-close.svg"
      alt=""
    />
  );
};

type BaseVideoModalProps = {
  video?: BrightcoveVideoProps | YoutubeVideoProps;
  videoId?: string;
  playerId?: string;
  playlistId?: string;
  openCtaClassName?: string;
  openCtaChildren?: JSX.Element | JSX.Element[];
  youtubeVideo?: boolean;
  brightcoveVideo?: boolean;
  isPlaylist?: boolean;
};

const BaseVideoModal = (props: BaseVideoModalProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalRoot, setModalRoot] = useState<HTMLElement | null>(null);
  const { video } = props;
  const i18n = useI18n();
  const closeLabel = i18n ? i18n.t('Close') : 'Close';

  useEffect(() => {
    setModalRoot(document.getElementById('modal-root'));
  }, []);

  const isYoutubeVideo =
    props.youtubeVideo || (video as YoutubeVideoProps)?.fields?.sourceId?.value != undefined;
  const isBrightcoveVideo =
    props.brightcoveVideo ||
    (!isYoutubeVideo && (video as BrightcoveVideoProps)?.fields?.videoReference != undefined);

  const youtubeVideoId =
    props.videoId || ((video as YoutubeVideoProps)?.fields?.sourceId?.value as string);

  const brightcoveVideoId =
    props.videoId ||
    ((video as BrightcoveVideoProps)?.fields?.videoReference?.[0]?.fields?.ID?.value as string);
  const brightcovePlayerId =
    props.playerId ||
    ((video as BrightcoveVideoProps)?.fields?.player?.fields?.ID?.value as string);
  const autoplay =
    props.youtubeVideo && (video as YoutubeVideoProps)?.fields?.autoPlay?.value == true;

  const modalContent =
    isOpen && modalRoot
      ? ReactDOM.createPortal(
          <div className="bc-modal fixed flex items-center justify-center top-0 left-0 w-full h-screen z-50">
            <div
              className="modal-backdrop fixed top-0 bg-almost-black h-screen opacity-80 w-full z-10"
              onClick={(): void => setIsOpen(false)}
            />
            <div className="modal-core bg-white relative flex flex-col p-4 m-6 justify-center z-50 rounded w-912">
              <button
                type="button"
                onClick={(): void => setIsOpen(false)}
                className="modal-close-btn absolute right-0 top-0 translate-x-50p -translate-y-50p hover:cursor-pointer"
              >
                {closeIcon()}
                <span className="sr-only">{closeLabel}</span>
              </button>

              {isYoutubeVideo && (
                <YoutubeEmbededVideo
                  isPlaylist={props.isPlaylist}
                  videoId={youtubeVideoId}
                  autoplay={autoplay}
                />
              )}

              {isBrightcoveVideo && (
                <BrightcoveEmbededVideo
                  videoId={brightcoveVideoId}
                  playerId={brightcovePlayerId}
                  playlistId={props.playlistId}
                />
              )}
            </div>
          </div>,
          modalRoot
        )
      : null;

  return (
    <>
      <button className={props.openCtaClassName} onClick={(): void => setIsOpen(true)}>
        {props.openCtaChildren}
      </button>

      {modalContent}
    </>
  );
};

export default BaseVideoModal;
