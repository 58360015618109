import {
  AtomicResultSectionExcerpt,
  AtomicResultSectionTitle,
  Result,
  AtomicResultSectionBottomMetadata,
  AtomicResultHtml,
  AtomicResultSectionVisual,
  AtomicResultSectionActions,
} from '@coveo/atomic-react';
import { Dispatch, SetStateAction, useState } from 'react';
import ResultImage from '../../atomic-components/ResultImage';
import SitecoreTaxonomyTags from '../../atomic-components/SitecoreTaxonomyTags';
import SearchTitle from '../../atomic-components/SearchTitle';
import { PriceDataItem, getPriceRange } from 'lib/commerce/product/price-helpers';

const styles = `
a,button {outline:0}
.intro-text {
  color: var(--atomic-on-primary);
}
.unitprice {
  color: var(--atomic-mp-txt-bright);
}
.promo-price {
  color: var(--atomic-mp-primary);
}
.regular-price {
  color: var(--atomic-mp-neutral);
  text-decoration-line: line-through;
}
.item-description {
  margin-top: 28px;
}
.item-description-smaller {
  min-height: 50px;
}
.item-description-inner {
  color: var(--atomic-mp-neutral);
  font-size: var(--atomic-text-base);
}
.buy-now-button {
  background-color: var(--atomic-mp-btn-primary);
  color: var(--atomic-mp-txt-bright);
}
.buy-now-button:disabled {
  background-color: var(--atomic-mp-neutral-medium);
  color: var(--atomic-mp-txt-bright);
}
.learn-more-button {
  color: var(--atomic-mp-primary);
  border-color: var(--atomic-mp-primary);
}
.image-wrapper {
  display: block;
}
.promo-wrapper {
  position: absolute !important;
  top: -15px;
  right: 0;
  z-index: 45;
}
.promo-wrapper-left {
  width: 0;
  height: 0;
  position: absolute;
  top: -15px;
  right: 110px;
  border-top: 14px solid var(--atomic-mp-background);
  border-bottom: 14px solid var(--atomic-mp-bg-card);
  border-right: 14px solid var(--atomic-mp-primary);
}
.promo-wrapper-center {
  background-color: var(--atomic-mp-primary);
  text-align: center;
  color: var(--atomic-mp-txt-bright);
  width: 130px;
  height: 28px;
  position: absolute;
  top: -15px;
  right: -20px;
}
.promo-wrapper-tail {
  border-top-width: 8px;
  border-bottom-width: 8px;
  border-left-width: 8px;
  border-top-color: var(--atomic-mp-primary);
  border-left-color: var(--atomic-mp-primary);
  border-right-color: var(--atomic-mp-primary);
  border-bottom-color: transparent;
  border-style: solid;
  width: 0;
  height: 40px;
  position: absolute;
  top: -15px;
  right: -25px;
}
`;

export const MarketplaceTemplate: React.FC<{
  result: Result;
  buyHandler: (productId: string, setShowBuyNowSpinner: Dispatch<SetStateAction<boolean>>) => void;
  currencySymbol: string;
  t: (st: string) => string;
  scrollPosition: Record<string, number> | null;
}> = ({ result, buyHandler, currencySymbol, t, scrollPosition }) => {
  const { clickUri } = result;
  const { searchtitle, productcategory, searchimageurl } = result.raw;
  const priceData = result.raw.priceData as PriceDataItem;
  const isDiscounted =
    priceData && priceData.discountValue !== undefined && priceData.discountValue > 0;

  const { lowestPrice, highestPrice } = getPriceRange(priceData);
  const hasPriceRange = priceData && priceData.pricesArray.length > 1 && lowestPrice < highestPrice;
  const [isInCart, setIsInCart] = useState<boolean>(result.raw.isInCart as boolean);
  const productid = priceData?.pricesArray.length === 1 ? priceData.pricesArray[0].productId : null;

  const buyNowHandler = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    const target = event.currentTarget as HTMLButtonElement;
    target.disabled = true;
    buyHandler(productid as string, setIsInCart);
  };

  return (
    <div className="relative">
      {isDiscounted && (
        <div className="promo-wrapper">
          <label>
            <div>
              <div className="promo-wrapper-left"></div>
              <div className="promo-wrapper-center bg-mp-background-primary text-center text-mp-txt-bright text-xxs leading-7 font-semibold uppercase tracking-[1px] w-[130px] h-[28px]">
                {priceData.discountValue}% OFF
              </div>
              <div className="promo-wrapper-tail"></div>
            </div>
          </label>
        </div>
      )}
      <style>{styles}</style>
      <AtomicResultSectionVisual
        className="image-wrapper"
        style={{ aspectRatio: '5/3', overflow: 'hidden', display: 'block', height: '45%' }}
      >
        <ResultImage alt="" src={searchimageurl as string} scrollPosition={scrollPosition} />
      </AtomicResultSectionVisual>
      <AtomicResultSectionTitle>
        <SearchTitle searchTitle={searchtitle as string} href={clickUri} />
      </AtomicResultSectionTitle>
      <div className={`item-description ${isDiscounted ? 'item-description-smaller' : ''}`}>
        <AtomicResultSectionExcerpt className="">
          <AtomicResultHtml field="searchdescription" className="item-description-inner" />
        </AtomicResultSectionExcerpt>
      </div>
      <AtomicResultSectionActions>
        <div className="w-full">
          <div className="flex w-full justify-between">
            <div>
              {hasPriceRange && (
                <div className="unitprice text-xl mb-3.5">
                  {currencySymbol}
                  {lowestPrice} - {highestPrice}
                </div>
              )}
              {!hasPriceRange && isDiscounted && (
                <div>
                  <span className="regular-price">{`${currencySymbol}${priceData?.pricesArray[0].listPrice}`}</span>
                </div>
              )}
              {!hasPriceRange && priceData != null && (
                <div className={`${isDiscounted ? 'promo-price' : 'unitprice'} text-xl`}>
                  {priceData.pricesArray[0].unitPrice > 0 ? currencySymbol : ''}
                  {priceData.pricesArray[0].unitPrice > 0
                    ? priceData.pricesArray[0].unitPrice
                    : t('MP_Free_Price')}
                </div>
              )}
            </div>
            <div>
              {!hasPriceRange && priceData != null && (
                <div className="float-right inline-block">
                  {
                    <button
                      onClick={(e): void => buyNowHandler(e)}
                      className="action-button buy-now-button px-4 py-2 mr-1 rounded"
                      disabled={isInCart}
                    >
                      {`${isInCart ? t('MP_In_Cart') : t('MP_Buy_Now')}`}
                    </button>
                  }
                </div>
              )}
            </div>
          </div>
          <div className="mt-1">
            <a href={clickUri}>
              <button className="action-button learn-more-button mr-1 border-b">
                {t('MP_Learn_More')}
              </button>
            </a>
          </div>
        </div>
      </AtomicResultSectionActions>
      {productcategory !== undefined && (
        <AtomicResultSectionBottomMetadata>
          <SitecoreTaxonomyTags
            taxonomyValues={productcategory as string[]}
            taxonomyName="productcategory"
          />
        </AtomicResultSectionBottomMetadata>
      )}
    </div>
  );
};

export default MarketplaceTemplate;
